import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import ForgottenPasswordForm from './ForgottenPasswordForm';
import { userPasswordResetAction } from '../../store/actions/userAction';

class ForgottenPasswordFormContainer extends Component {
  constructor(props){
    super(props);
    this.state={ email:'', ifSuccess: null, errors:{}, clicked:false, actionId:'', };
  }
  resetPassword = () => {
    //this.setState({clicked:true})
    const actionId =  Math.random();
    this.setState({clicked:true,actionId})
    const { email } = this.state;
    this.props.passwordReset(email, actionId);
    this.setState(prevState => ({
      ifSuccess: prevState.ifSuccess===null ? actionId : null,
    }))
  }
  componentDidUpdate(prevProps){
    if (prevProps.errorMessage!==this.props.errorMessage && this.state.clicked===true) {
      this.setState({errors:this.props.errorMessage});
    }
    if (prevProps.success!==this.props.success && (this.props.success === this.state.actionId)) {
    //if (prevProps.success!==this.props.success && (this.props.success === this.state.ifSuccess)) {
      this.setState({errors:{}, ifSuccess: this.state.actionId});
    }
  }

  render() {
    const { ifSuccess, errors } = this.state;
    return (
      <Fragment>
        <ForgottenPasswordForm
            formData={this.state}
            onChange={s=>this.setState(s)}
            error={errors}
            ifSuccess={ifSuccess===this.props.success}
            action={this.resetPassword}/>
      </Fragment>
    );
  }
}

function mapStateToProps(state) {
  return {
    success:state.success,
    errorMessage:state.error,
  }
}

function mapDispatchToProps(dispatch) {
  return {
    passwordReset: (email, actionId) => dispatch(userPasswordResetAction(email, actionId))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ForgottenPasswordFormContainer);
