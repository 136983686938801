import request from '../../utils/request';

export const USER_LIST_RECEIVED = 'USER_LIST_RECEIVED';

export const userListReceivedAction = (userList) => {
  return {
    type: USER_LIST_RECEIVED,
    userList: userList,
  };
}

export default () => {
  return (dispatch) => {
    request('/user/list/', 'get')
    .then(r => dispatch(userListReceivedAction(r)))
  }
}
