import React, { Component } from 'react';
import { connect } from 'react-redux';
import EditOrAddBusinessDrinkPop from './EditOrAddBusinessDrinkPop';
import { updateDrinkAction, createDrinkAction } from '../../store/actions/businessDrinkAction';
/*import { BASE_URL } from 'App';*/
import { languages } from '../../i18n';

class EditOrAddBusinessDrinkPopContainer extends Component {
	constructor (props) {
	    super(props);
	    const { drink, businessId } = this.props;
			const compositionLangs = {}
			const nameLangs = {}
			for (let lang of languages) {
				let name = `composition_${lang}`
				let data = `description${lang.charAt(0).toUpperCase() + lang.slice(1)}`
				/*if (lang === "cs") {
					compositionLangs[name] = drink['description'] || ""
				} else {*/
					compositionLangs[name] = drink[data] || ""
				//}
				nameLangs[`name_${lang}`] = drink[`name${lang.charAt(0).toUpperCase() + lang.slice(1)}`] || ""
			}
	    this.state = {
				names: nameLangs,
		    //name: drink.name || "",
				//composition: drink.description || "",
				compositions: compositionLangs,
				price: drink.price || "",
				selectedImage: "",
				id: (drink||{}).id,
				business: businessId,
				ifSuccess: null,
				errors:{},
				clicked:false,
				actionId:'',
		 	}
		};
		componentDidUpdate(prevProps){
	    if (prevProps.errorMessage!==this.props.errorMessage && this.state.clicked===true) {
	      this.setState({errors:this.props.errorMessage});
	    }
	    //if (prevProps.success!==this.props.success && (this.props.success === this.state.ifSuccess)) {
			if (prevProps.success!==this.props.success && (this.props.success === this.state.actionId)) {
				this.setState({errors:{}, ifSuccess: this.state.actionId});
	    }
	  }
	editDrink = () => {
		//this.setState({clicked:true})
		const actionId =  Math.random();
		this.setState({clicked:true,actionId})
	    this.props.edit(this.state, actionId);
	    this.setState(prevState => ({
	      ifSuccess: prevState.ifSuccess===null ? actionId : null,
	    }))
	}
	addDrink = () => {
		//this.setState({clicked:true})
		const actionId =  Math.random();
		this.setState({clicked:true,actionId})
	    this.props.create(this.state, actionId);
	    this.setState(prevState => ({
	      ifSuccess: prevState.ifSuccess===null ? actionId : null,
	    }))
	}
	imageSelectedHandler = (event) => {
	    this.setState({selectedImage:event.target.files[0]})
  	}

  	render() {
    	const { /*edit, create, */isVisible, handleClickToEdit, /*drink,*/ success } = this.props;
    	const { selectedImage, /*name, compositions, price,*/ id, /*ifSuccess,*/ errors, ifSuccess } = this.state;

    	//const disabled = id ? ((name === drink.name) /*&& (compositions.composition_cs === drink.description)*/ && (price === drink.price) && (selectedImage === "")) : ((name === "") || /*(compositions.composition_cs === "") ||*/ (price === "") || (selectedImage === ""));

		return (
			<EditOrAddBusinessDrinkPop
				imageSelectedHandler={this.imageSelectedHandler}
        selectedImage={selectedImage}
				error={errors}
				formData={this.state}
				onChange={v=>this.setState(v)}
				action={id ? this.editDrink : this.addDrink }
				editMode={id ? true : false }
				ifSuccess={ifSuccess===success}
				isVisible={isVisible}
				handleClickToEdit={handleClickToEdit} />
		);
  	}
}
function mapStateToProps(state) {
  return {
    success:state.success,
		errorMessage:state.error,
  }
}

function mapDispatchToProps(dispatch) {
  	return {
      	edit: (editInfo, actionId) => dispatch(updateDrinkAction(editInfo, actionId)),
      	create: (createInfo, actionId) => dispatch(createDrinkAction(createInfo, actionId)),
  	}
}

export default connect(mapStateToProps, mapDispatchToProps)(EditOrAddBusinessDrinkPopContainer);
