import React, { Component } from 'react';
import { connect } from 'react-redux';
import BusinessLabel from './BusinessLabel';

class BusinessLabelContainer extends Component {
	render() {
		const {user, onClickBusinessProfile, onClickBusinessDrinks, isBusinessProfileActive, isBusinessDrinksActive } = this.props;
		return (
			<BusinessLabel 
				user={user} 
				onClickBusinessProfile={onClickBusinessProfile} 
				onClickBusinessDrinks={onClickBusinessDrinks} 
				isBusinessProfileActive={isBusinessProfileActive} 
				isBusinessDrinksActive={isBusinessDrinksActive} />
		);
	}
}

function mapStateToProps(state) {
	return {
		user: state.user,
	}
}

export default connect(mapStateToProps)(BusinessLabelContainer);