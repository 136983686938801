import React, { Fragment, Component } from 'react';

import TopBarContainer from '../../components/topBar/TopBarContainer';
import CatalogContainer from '../../components/catalog/CatalogContainer';
import BusinessDetail from '../../components/businessDetail/BusinessDetail';
import Footer from '../../components/footer/Footer'

class CatalogPage extends Component{
	constructor(props) {
		super(props);
		this.state = {
			business: null,
		}
	}
	handleClickToShow = (business, e = {}) => {
		if (business) {
			this.setState({business: business})
		} else {
			if ((e.target || {}).className === "business-detail" || (e.target||{}).className === "closeIcon")
				this.setState({business: null})
		}
	}
	render() {
		const { business } = this.state;
		return (
			<Fragment>
				<div className="page-content-container">
					<div style={{minWidth: 400}}>
						<TopBarContainer topbarStyle="catalog" />
					</div>
					<div className="bg-bar-catalog">
						{/*<div className="bg-bar-blur">*/}
							<div style={business ? {overview: 'hidden'} : {}}>
								<CatalogContainer handleClickToShow={this.handleClickToShow} />
							</div>
							{
								business &&
								<div className={ business ? "business-detail" : "" } style={ business ? {display: "block"} : {display: "none"}} onClick={e => this.handleClickToShow(null, e)}>
										<BusinessDetail handleClickToShow={this.handleClickToShow} business={business} />
								</div>
							}
						{/*</div>*/}
					</div>
				</div>
				<div style={{minWidth: 400}}>
					<Footer />
				</div>
			</Fragment>
		);
	}
}

export default CatalogPage;
