import React, { Component } from 'react';

class BusinessImagesCarousel extends Component {
  render() {
    const { images } = this.props;
    const imagesIndicator = images.map((img, index) => {
        return <li key={index+100} data-target="#demo" data-slide-to={index} className={ index === 0 ? "active" : ""}></li>
    });
    const imagesCarousel = images.map((img, index) => {
      return (
        <div key={index+200} className={ index === 0 ? "carousel-item active" : "carousel-item"} style={{height: 348, backgroundPosition: 'center', backgroundRepeat: 'no-repeat',  objectFit: 'cover', objectPosition: 'center'}}>
          <img key={index+300} src={img.image} alt="" style={{backgroundPosition: 'center', backgroundRepeat: 'no-repeat',  objectFit: 'cover', objectPosition: 'center'}}/>
        </div>
      )
    });
    return (
      <div id="demo" className="carousel slide" data-ride="carousel" style={{height: "calc(100% - 34px)"}}>
        {  /* Indicators */}
        <ul className="carousel-indicators">
          {imagesIndicator}
        </ul>
        { /* The slideshow */}
        <div className="carousel-inner">
          {imagesCarousel}
        </div>
        {/* Left and right controls */}
        <a className="carousel-control-prev" href="#demo" data-slide="prev">
          <span className="carousel-control-prev-icon"></span>
        </a>
        <a className="carousel-control-next" href="#demo" data-slide="next">
          <span className="carousel-control-next-icon"></span>
        </a>
      </div>
    )
  }
}

export default BusinessImagesCarousel;
