import React, { Component } from 'react';
import { connect } from 'react-redux';
import BusinessDrinksTable from './BusinessDrinksTable';

class BusinessDrinksTableContainer extends Component {
	render() {
		const {user, handleChangeOfDrinkName, handleClickToEdit, handleClickToShow, /*deleteDrink,*/ handleClickToConfirm } = this.props;
		return (
			<BusinessDrinksTable
				user={user}
				handleChangeOfDrinkName={handleChangeOfDrinkName}
				handleClickToEdit={handleClickToEdit}
				handleClickToShow={handleClickToShow}
				handleClickToConfirm={handleClickToConfirm} />
		);
	}
}

function mapStateToProps(state) {
	return {
		user: state.user,
	}
}

export default connect(mapStateToProps)(BusinessDrinksTableContainer);
