import React, { Component } from 'react';
import { connect } from 'react-redux';
import BusinessProfileTable from './BusinessProfileTable';

class BusinessProfileTableContainer extends Component {
	render() {
		const { user, handleChangeOfDrinkName, handleClickToEdit, handleClickToChange } = this.props;
		return (
			<BusinessProfileTable 
				user={user} 
				handleChangeOfDrinkName={handleChangeOfDrinkName} 
				handleClickToEdit={handleClickToEdit}
				handleClickToChange={handleClickToChange} />
		);
	}
}

function mapStateToProps(state) {
	return {
		user: state.user,
	}
}


export default connect(mapStateToProps)(BusinessProfileTableContainer);