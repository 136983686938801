import React, { Component } from 'react';
import { connect } from 'react-redux';
import Catalog from './Catalog';
import businessListAction from '../../store/actions/businessListAction';

class CatalogContainer extends Component {
	componentDidMount() {
		this.props.getBusinessList();
	}
	render() {
		const { businessList, handleClickToShow } = this.props;
		return (
			<Catalog businessList={businessList} handleClickToShow={handleClickToShow} />
		);
	}
}

function mapStateToProps(state) {
	return {
		businessList: state.businessList,
	}
}

function mapDispatchToProps(dispatch){
	return {
		getBusinessList: () => dispatch(businessListAction())
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(CatalogContainer);
