import React, { Component } from 'react';
import { translate } from 'react-i18next';
import Button from '../common/button/Button';
import Form from '../common/form/Form';
import { Redirect } from 'react-router-dom';
import { Link } from 'react-router-dom';
import Logo from '../../styles/icons/logo.svg';

//const btnStyle = {backgroundImage: "linear-gradient(-90deg, #b63184, #e9427a)", fontSize: 14, fontWeight: 600};
const btnStyle = {color: "#FEFEFE",	fontFamily: "Poppins",	fontSize: 16,	fontWeight: "bold",	textAlign: "center",	backgroundColor: "#FE2183"}

class ForgottenPasswordForm extends Component {
	announceSuccess() {
		const { ifSuccess, t } = this.props;
		if (ifSuccess === true) {
			alert(t('emailSended'))
		}
	}
	render() {
		const { action, formData, onChange, ifSuccess, error, t } = this.props ;
		const errLabel = ((error.nonFieldErrors||[])[0] || (error.detail)) ? <label style={{color:"red"}}>{(error.nonFieldErrors||[])[0]||error.detail}</label> : '';
		return (
			<div className="bg-bar-login">
				{this.announceSuccess()}
				<div className="bg-bar-blur bg-bar-blur-login">
					<div className="login-form-container tac">
						{ifSuccess===true? <Redirect to="/" /> :null}
						<Link to="/"><div className="appName"><img src={Logo} width="170px" height="58px" alt="logo" /></div></Link>
						<div className="registration-form">
							<h3 className="form-header">{t('forgottenPassword')}</h3>
							<Form
		            formData={formData}
		            onChange={onChange}
              	schema={[
			            {name:t('email'), path:'email', type:'email', isInvalid:error.email?'errorClass':'', errorMsg:error.email?error.email:null },
		          	]}/>
          		<div className="options-links-container">
								<label className="tal">{t('hint')}</label>
								{errLabel}
								<div className="flex">
									<Link className="options-link tal flex-grow-help" to="/login">{t('back')}</Link>
									<Button title={t('sendAnEmail')} onClick={action} style={btnStyle}>{t('sendAnEmail')}</Button>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	}
}

export default translate('forgottenPasswordForm')(ForgottenPasswordForm);
