import { MAKE_PAYMENT_RECEIVED } from '../actions/makePaymentAction';
import humps from 'humps';

export default (state = [], action) => {
  	switch (action.type) {
    	case MAKE_PAYMENT_RECEIVED:
      		const newMakePaymentResponse = humps.camelizeKeys(action.paymentResponse)
      		return newMakePaymentResponse;
    	default:
    		return state;
  	}
}
