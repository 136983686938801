import React, { Component } from 'react';
import { connect } from 'react-redux';
import AfterPayment from './AfterPayment';
import getPaymentInfoAction from '../../store/actions/getPaymentInfoAction'
import resetPaymentAction from '../../store/actions/resetPaymentAction'

class AfterPaymentContainer extends Component {
	constructor(props) {
		super(props);
		this.props.resetPayment()
		this.props.getPaymentInfo(window.location.href.toString().slice(window.location.href.toString().indexOf('?id=')+4))
	}
	render() {
		const {payment, lang} = this.props
		return (
			<AfterPayment payment={payment} lang={lang} />
		);
	}
}

function mapStateToProps(state) {
	return {
		payment: state.payment,
		lang: state.lang,
	}
}

function mapDispatchToProps(dispatch){
	return {
		getPaymentInfo: (id) => dispatch(getPaymentInfoAction(id)),
		resetPayment: () => dispatch(resetPaymentAction())
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(AfterPaymentContainer);
