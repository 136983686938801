import React, { Component } from 'react';
import DatePicker from 'react-datepicker';
import moment from 'moment';
import 'moment/locale/cs';
import 'react-datepicker/dist/react-datepicker.css';
import Textarea from '../textarea/Textarea';
import validator from 'validator';

const allowedCharacter = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', ':', '-']

class Input extends Component {
  constructor(props){
    super(props);
    this.handleChange = this.handleChange.bind(this);
    this.handleOptionChange = this.handleOptionChange.bind(this);
    this.handleChangeNumber = this.handleChangeNumber.bind(this);
    this.handleChangeOpeningHours = this.handleChangeOpeningHours.bind(this);
  }

  render() {
    const {type,onChange, options, validation, optionValue, isInvalid, errorMsg, backgroundDark, ...rest} = this.props;
    let input;
    if (type === "date"){
      input =  <DatePicker selected={this.props.value || moment()} dateFormat="DD-MM-YYYY" locale="cs" onChange={onChange} peekNextMonth showMonthDropdown showYearDropdown/>
    }
    else if (type === "dropdown"){
      input =
      <select className="input-style" onChange={this.handleChange} {...rest}>
      {options.map(o=><option key={o.value} value={o.value}>{o.label}</option>)}
      </select>
    }
    else if (type === "email"){
      input = <input className={`${backgroundDark === true ? "input-style-popup" : "input-style"} ${isInvalid ? isInvalid : ''}`} type={type||'text'} onChange={this.handleChange} {...rest} required autoComplete='email' />
    }
    else if (type === "textarea"){
      input = <Textarea className="textarea" onChange={this.handleChange} {...rest} required />
    }
    else if (type === "checkbox"){
      input = <input checked={this.props.value||false} type={type} onChange={e=>onChange(e.target.checked)} {...rest} />
    }
    else if (type === "radio"){
      input = <input
                checked={this.props.value===optionValue}
                type={type}
                onChange={e=>this.handleOptionChange(e, optionValue)}
                {...rest}/>
    }
    else if (type === "number"){
      input = <input className={`${backgroundDark === true  ? "input-style-popup" : "input-style"} ${isInvalid ? isInvalid : ''}`} type={type||'text'} onChange={this.handleChangeNumber} required {...rest} />
    }
    else if (type === "price"){
      input = <input className={`${backgroundDark === true  ? "input-style-popup" : "input-style"} ${isInvalid ? isInvalid : ''}`} type="text" onChange={this.handleChangeNumber} required {...rest} />
    }
    else if (type === "ico"){
      input = <input className={`${backgroundDark === true  ? "input-style-popup" : "input-style"} ${isInvalid ? isInvalid : ''}`} type="text" onChange={this.handleChangeNumber} minLength="1" maxLength="8" {...rest} />
    }
    else if (type === "zipCode"){
      input = <input className={`${backgroundDark === true  ? "input-style-popup" : "input-style"} ${isInvalid ? isInvalid : ''}`} type="text" onChange={this.handleChangeNumber} maxLength="5" required {...rest} />
    }
    else if (type === "houseNo"){
      input = <input className={`${backgroundDark === true  ? "input-style-popup" : "input-style"} ${isInvalid ? isInvalid : ''}`} type="text" onChange={this.handleChangeNumber} required {...rest} />
    }
    else if (type === "tel"){
      input = <input className={`${backgroundDark === true  ? "input-style-popup" : "input-style"} ${isInvalid ? isInvalid : ''}`} type="tel" onChange={this.handleChangeNumber} minLength="9" maxLength="9" required {...rest} />
    }
    else if (type === "composition"){
      input = <input className={`${backgroundDark === true  ? "input-style-popup" : "input-style"} ${isInvalid ? isInvalid : ''}`} type="text" onChange={this.handleChange} maxLength="100" {...rest} />
    }
    else if (type === "barName"){
      input = <input className={`${backgroundDark === true  ? "input-style-popup" : "input-style"} ${isInvalid ? isInvalid : ''}`} type="text" onChange={this.handleChange} maxLength="15" required {...rest} />
    }
    else if (type === "name"){
      input = <input className={`${backgroundDark === true  ? "input-style-popup" : "input-style"} ${isInvalid ? isInvalid : ''}`} type="text" onChange={this.handleChange} maxLength="50" required {...rest} />
    }
    else if (type === "openingHours"){
      input = <input className={`${backgroundDark === true  ? "input-style-popup" : "input-style"} ${isInvalid ? isInvalid : ''}`} type="text" onChange={this.handleChangeOpeningHours} maxLength="11" required {...rest} />
    }
    else{
      input = <input className={`${backgroundDark === true  ? "input-style-popup" : "input-style"} ${isInvalid ? isInvalid : ''}`} type={type||'text'} onChange={this.handleChange} {...rest} autoComplete={type === "password" ? "current-password" : ""} />
    }
    return input;
  }

  handleOptionChange(e, optionValue) {
    const {onChange} = this.props;
    if (e.target.checked) {
      onChange(optionValue);
    }
  };

  handleChange(e){
    const newValue = e.target.value;
    const {onChange, validation} = this.props;
    if (!validation || validation(newValue)) {
      onChange(newValue);
    }
  }

  handleChangeOpeningHours(e){
    const newValue = e.target.value;
    const {onChange} = this.props;
    if (allowedCharacter.includes(newValue.charAt(newValue.length-1)) || newValue === '') {
      onChange(newValue);
    }
  }

  handleChangeNumber(e){
    let newValue = e.target.value;
    const {onChange} = this.props;

   if((validator.isInt(newValue)&&newValue>=0)) {
       onChange(Number(newValue));
       return;
    }
    if (newValue==='') {
       onChange(newValue);
    }
  }
}

export default Input;
