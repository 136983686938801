import request from '../../utils/request';
import { getCurrentUser } from './userAction';
import announceSuccess from './successAction';
import errorAction from './errorAction'
import { store } from '../store';

export const createDrinkAction = (r, actionId) =>{
  let formData = new FormData();
  //formData.append('name', r.name);
  let lang = (store.getState()['lang'])||'cs'
  for (let description in r.compositions) {
    switch(description) {
      case "composition_cs":
        if (lang === 'cs')
          formData.append('description', r.compositions.composition_cs);
        formData.append('description_cs', r.compositions.composition_cs);
        break
      default:
        if (lang === 'en' && description === 'composition_en')
          formData.append('description', r.compositions[description]);
        formData.append(`description${description.substring(description.indexOf('_'))}`, r.compositions[description]);
        break
    }
  }
  for (let name in r.names) {
    switch(name) {
      case "name_cs":
        if (lang === 'cs')
          formData.append('name', r.names.name_cs);
        formData.append('name_cs', r.names.name_cs);
        break
      default:
        if (lang === 'en' && name === 'name_en')
          formData.append('name', r.names[name]);
        formData.append(`name${name.substring(name.indexOf('_'))}`, r.names[name]);
        break
    }
  }

  formData.append('price', r.price);
  if (r.selectedImage !== '')
    formData.append('image', r.selectedImage);
  formData.append('business', r.business)
  return (dispatch) => {
    request('/user/business/drink/create/', 'POST', formData, 'multipart/form-data')
    .then(() => dispatch(getCurrentUser()))
    .then(()=>dispatch(announceSuccess(actionId)))
    .catch(e => e.then(err=>dispatch(errorAction(err))))
    .catch(e => {})
  }
}

export const updateDrinkAction = (r, actionId) =>{
  let formData = new FormData();
  //formData.append('name', r.name);
  let lang = (store.getState()['lang'])||'cs'
  for (let description in r.compositions) {
    switch(description) {
      case "composition_cs":
        if (lang === 'cs')
          formData.append('description', r.compositions.composition_cs);
        formData.append('description_cs', r.compositions.composition_cs);
        break
      default:
        if (lang === 'en' && description === 'composition_en')
          formData.append('description', r.compositions[description]);
        formData.append(`description${description.substring(description.indexOf('_'))}`, r.compositions[description]);
        break
    }
  }
  for (let name in r.names) {
    switch(name) {
      case "name_cs":
        if (lang === 'cs')
          formData.append('name', r.names.name_cs);
        formData.append('name_cs', r.names.name_cs);
        break
      default:
        if (lang === 'en' && name === 'name_en')
          formData.append('name', r.names[name]);
        formData.append(`name${name.substring(name.indexOf('_'))}`, r.names[name]);
        break
    }
  }
  formData.append('price', r.price);
  if (r.selectedImage !== '')
    formData.append('image', r.selectedImage);
  formData.append('business', r.business)
  return (dispatch) => {
    request('/user/business/drink/'+r.id+'/', 'PATCH', formData, 'multipart/form-data')
    .then(() => dispatch(getCurrentUser()))
    .then(()=>dispatch(announceSuccess(actionId)))
    .catch(e => e.then(err=>dispatch(errorAction(err))))
    .catch(e => {})
  }
}

export const deleteDrinkAction = (id, actionId) => {
  return (dispatch) => {
    request('/user/business/drink/'+id+'/', 'DELETE')
    .then(() => dispatch(getCurrentUser()))
    .then(()=>dispatch(announceSuccess(actionId)))
    .catch(e => {})
  }
}

export default {
  createDrinkAction,
  updateDrinkAction,
  deleteDrinkAction
}
