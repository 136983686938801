import React, { Component } from 'react';
import { connect } from 'react-redux';
import TopBar from './TopBar';
import logoutAction from '../../store/actions/logoutAction';
import languageAction from '../../store/actions/languageAction';

class TopBarContainer extends Component {
	render() {
		const {user, logout, lang, changeLang, topbarStyle} = this.props;
		return (
			<TopBar user={user} logout={logout} lang={lang} changeLang={changeLang} topbarStyle={topbarStyle} />
		);
	}
}

function mapStateToProps(state) {
	return {
		user: state.user,
		lang: state.lang,
	}
}

function mapDispatchToProps(dispatch){
	return {
		logout: ()=>dispatch(logoutAction()),
		changeLang: (lang)=>dispatch(languageAction(lang)),
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(TopBarContainer);
