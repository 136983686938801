import React, { Component } from 'react';
import Router from './pages/Router';

class App extends Component {
  render() {
    return (
    	<div className="h100">
        <Router  />
    	</div>
    );
  }
}

export default App;
