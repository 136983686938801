import React, { Component, Fragment } from 'react';
import { translate } from 'react-i18next';
import Button from '../common/button/Button';
import Form from '../common/form/Form';


class UploadBusinessImagePop extends Component {
	render() {
		const { formData, onChange, action, isVisible, handleClickToUpload, selectedImage, imageSelectedHandler, ifSuccess, disabled, t} = this.props;
		return (
			<Fragment>
				{
					isVisible &&
					(
						<div className="outer-upload-container" onClick={(ifSuccess === true) ? handleClickToUpload({}, {}, true) : e => handleClickToUpload(e, null)} >
							<div className="upload-form-container">
								<div className="edit-form">
									<span className="closeIcon" aria-hidden="true" onClick={e => handleClickToUpload(e, null)}>×</span>
									<p className="tal popup-header" key="header">{t('header')}</p>
									<div className="flex">
										<div className="mt10l16r30">
											<div>
												<input type="file" name="file" id="file" className="inputfile" onChange={imageSelectedHandler} />
												<label htmlFor="file" className="m0">{t('chooseImage')}</label>
											</div>
											<label className="uploading-image-name">{(selectedImage||{}).name}</label>
										</div>
										<label className="fs12 mt16lr16">{t('isTitle')}</label>
										<Form
											formData={formData}
											onChange={onChange}
											schema={[
												{name:t('isTitle'), path:'isTitle', type: "checkbox"},
											]}/>
										</div>
										<div className="flex upload-container">
											<Button title={t('upload')} disabled={disabled} onClick={() => action()} style={disabled ? {opacity: 0.5, backgroundColor: "#FE2183", fontSize: 14} : {backgroundColor: "#FE2183", fontSize: 14}}>{t('upload')}</Button>
										</div>
									</div>
								</div>
							</div>
						)
					}
				</Fragment>
			);
		}
}

export default translate('uploadBusinessImagePop')(UploadBusinessImagePop);
