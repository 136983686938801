import React, { Component } from 'react';
import { connect } from 'react-redux';
import { translate } from 'react-i18next'
import Customer from './Customer'
import makePaymentAction from '../../store/actions/makePaymentAction'
import getMembershipInfoAction from '../../store/actions/getMembershipInfoAction'
import cancelMembershipAction from './../../store/actions/cancelMembershipAction'
import { getCurrentUser } from '../../store/actions/userAction'

class CustomerContainer extends Component {
	constructor(props) {
		super(props)
		this.props.getMembershipInfo()
		this.props.getUser()
	}

	componentDidMount() {
		this.props.getMembershipInfo()
		this.props.getUser()
	}

	componentDidUpdate(prevProps) {
		const {makePaymentResponse} = this.props
		if (prevProps.makePaymentResponse.gwUrl === undefined || makePaymentResponse.gwUrl === undefined)
			return null
		if (prevProps.makePaymentResponse.gwUrl !== makePaymentResponse.gwUrl && makePaymentResponse.gwUrl && makePaymentResponse.gwUrl !== '') {
			this.props.getUser()
			window.location.href = makePaymentResponse.gwUrl
		}
		if (this.props.user.customer.membership.isCancelled && !prevProps.user.customer.membership.isCancelled) {
			alert(this.props.t('membershipCanceled'));
		}
		return null
	}

	render() {
		const { makePaymentAction, user, cancelMembership, membership, lang } = this.props;
		return (
				<Customer user={user} cancelMembership={cancelMembership} makePaymentAction={makePaymentAction} membership={membership} lang={lang} />
		);
	}
}

function mapStateToProps(state) {
	return {
		user: state.user,
		makePaymentResponse:  state.makePaymentResponse,
		membership: state.membership,
		lang: state.lang,
	}
}

function mapDispatchToProps(dispatch){
	return {
		makePaymentAction: () => dispatch(makePaymentAction()),
		getMembershipInfo: () => dispatch(getMembershipInfoAction()),
		cancelMembership: () => dispatch(cancelMembershipAction()),
		getUser: () => dispatch(getCurrentUser()),
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(translate('customer')(CustomerContainer));
