import request from '../../utils/request'
export const MEMBERSHIP_INFO_RECEIVED = 'MEMBERSHIP_INFO_RECEIVED';

export const membershipInfoReceived = (membershipInfo) => {
  return {
    type: MEMBERSHIP_INFO_RECEIVED,
    membershipInfo: membershipInfo,
  };
}

export default () => {
  return (dispatch) => {
    request('/membership/settings/', 'get')
    .then(r => dispatch(membershipInfoReceived(r)))
    .catch(e=>{})
  }
}
