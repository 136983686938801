import { USER_LIST_RECEIVED } from '../actions/userListAction';
import humps from 'humps';
import moment from 'moment';

export default (state = [], action) => {
  switch (action.type) {
    case USER_LIST_RECEIVED:
      const newUserList = humps.camelizeKeys(action.userList)
      for (const user of newUserList) {
        user.birthDate = moment(user.birthDate);
      }
      return newUserList; 
    default:
      return state;
  }
}
 