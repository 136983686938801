import { LOGOUT } from '../actions/logoutAction';
import { initialState } from '../store';

export default (state = initialState, action) => {
  switch (action.type) {
    case LOGOUT:
      const { businessList, lang } = state;
      const newState = Object.assign(initialState);
      newState["businessList"] = businessList;
      newState["lang"] = lang
      return newState;
    default:
      return state;
  }
}
