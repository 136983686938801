import request from '../../utils/request'
import getPaymentInfoAction from './getMembershipInfoAction'
import { getCurrentUser } from './userAction'

export default () => {
  return (dispatch) => {
    request('/membership/cancel/', 'POST', {})
    .then(() => dispatch(getCurrentUser()))
    .then(() => dispatch(getPaymentInfoAction()))
    .catch(e => e.then(err=>alert(err)))
    .catch(e=>{})
  }
}
