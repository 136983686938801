import React, { Component } from 'react';
import BusinessDrinksTableContainer from '../businessDrinksTable/BusinessDrinksTableContainer';
import DrinkOverview from '../drinkOverview/DrinkOverview';

class BusinessDrinks extends Component {
	constructor(props) {
		super(props);
		this.state = {
			drink: {},
		};
		this.handleClickToShow = this.handleClickToShow.bind(this);
	}
	handleClickToShow(e, drink, ifEdit = false) {
		if (((e.target||{}).className === "table-company-row" || (e.target||{}).className === "lh2 table-company-row") && (e.target||{}).nodeName !== "svg")
			this.setState({drink: drink});
		if (ifEdit)
			this.setState({drink: {}});
	}
	render() {
		const { handleClickToEdit, handleClickToConfirm } = this.props;
		const {drink} = this.state;
		return (
			<div style={{margin: "0 20px"}}>
				<BusinessDrinksTableContainer handleClickToEdit={handleClickToEdit} handleClickToShow={this.handleClickToShow} handleClickToConfirm={handleClickToConfirm} />
				<DrinkOverview drink={drink} />
			</div>
		);
	}
}

export default BusinessDrinks;
