import React, {Component} from 'react';
import { translate } from 'react-i18next';
import BusinessCard from '../businessCard/BusinessCard';


class Catalog extends Component {
	render() {
		const { businessList, handleClickToShow, t } = this.props;
		const bList = businessList || [];
		const businessCards = bList.map((business, i) => {
			let zipCode = (business.zipCode||"").toString();
			let address = `${business.street || ""} ${business.houseNo || ""}, ${business.town || ""}, ${zipCode.substring(0,3)} ${zipCode.substring(3,5)}`;
			let background = business.images.find(img => img.isTitle === true) || {};
			return <BusinessCard key={i} business={business} handleClickToShow={handleClickToShow} name={business.barName || ""} description={business.description || ""} address={address} drinks={business.drinks || []} background={background.image || ""}/>
		});
		return (
			<div className="catalog-outer-container">
				<div className="container-fluix">
					<h1 className="upper">{t('catalog')}</h1>
					<div className="catalog-inner-container">
						{businessCards}
					</div>
				</div>
			</div>
		);
	}
}

export default translate('catalog')(Catalog);
