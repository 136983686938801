import React, {Component} from 'react'
import { translate } from 'react-i18next'
import { Link } from 'react-router-dom';

class AfterPayment extends Component {
  render() {
    const {payment, lang} = this.props
    //  tady menit text zpravy
    let message = ''
    let emoji = ''
    switch(payment.state) {
      case "PAID":
        message = (lang === 'cs') ? 'Vaše platba proběhla v pořádku. Děkujeme' : 'Your payment was accepted. Thank you'
        emoji = (<span role="img" aria-label="smile-emoji">🙂</span>)
        break
      case "CANCELED":
        message = (lang === 'cs') ? 'Vaše platba byla bohužel zrušena, prosím zkuste to ještě jednou, nebo nás neváhejte kontaktovat na podpora@freelyapp.cz' : 'Your payment was canceled. Please try again or contact support@freelyapp.cz'
        emoji = (<span role="img" aria-label="sad-emoji">😟</span>)
        break
      case "TIMEOUTED":
       message = (lang === 'cs') ? 'Vypršel časový limit pro platbu, zkuste prosím ještě jednou.' : 'Time limit for your payment is over. Please try again'
       emoji = (<span role="img" aria-label="very-sad-emoji">🙁</span>)
       break
      default:
       break
    }
    return (
      <div className="acknowledgment-container">
        <h1 className="acknowledgment-header">{message}{emoji}</h1>
        <Link to="/customer">
          <label className="acknowledgment-link">{(lang === 'cs') ? 'Zpět na stránku s předplatným' : 'Return to subscription page'}</label>
        </Link>
      </div>
    )
  }
}

export default translate('afterPayment')(AfterPayment)
