import React, { Component, Fragment } from 'react';
import { translate } from 'react-i18next';
import Button from '../common/button/Button';
import Form from '../common/form/Form';

class EditOrAddBusinessDrinkPop extends Component {
	constructor(props) {
		super(props)
		const schema = this.updateSchema(false)
		this.state = {
			schema: schema,
			ifChange: false,
		}
		this.updateSchema = this.updateSchema.bind(this);
	}
	componentDidUpdate(prevProps){
		if (prevProps.error!==this.props.error) {
			this.setState({ifChange: true});
		}
	}
	updateSchema(shouldUpdateState = true) {
		const { formData, error, t } = this.props;
		const schema = []
		for (let name in formData.names) {
			let path = `names.${name}`
			if (name === 'name_cs') {
				schema.push({name:t(name), path:path, errorMsg:error.name?error.name:null, isInvalid:error.name?'errorClass':'' })
			} else {
				schema.push({name:t(name), path:path, /*errorMsg:error.name?error.name:null, isInvalid:error.name?'errorClass':''*/ })
			}
		}
		for (let composition in formData.compositions) {
			let path = `compositions.${composition}`
			if (composition === 'composition_cs') {
				schema.push({name:t(composition), path:path, errorMsg:error.description?error.description:null, isInvalid:error.description?'errorClass':'' })
			} else {
				schema.push({name:t(composition), path:path, })
			}
		}
		for (let data in formData){
			switch(data) {
				/*case "name":
					schema.push({name:t('name'), path:'name', type:'name', isInvalid:error.name?'errorClass':'', errorMsg:error.name?error.name:null })
					break
				case "composition":
					schema.push({name:t('composition'), path:'composition', type:'composition', isInvalid:error.composition?'errorClass':'', errorMsg:error.composition?error.composition:null })
					break*/
				case "price":
					schema.push({name:t('price'), path:'price', type:'price', isInvalid:error.price?'errorClass':'', errorMsg:error.price?error.price:null })
					break
				default:
					break
			}
		}
		if (shouldUpdateState)
			this.setState({schema:schema, ifChange: false})
		else
			return schema
	}
	render() {
		const { formData, onChange, action, isVisible, handleClickToEdit, selectedImage, imageSelectedHandler, disabled, ifSuccess, editMode, error, t} = this.props;
		if (this.state.ifChange) {
			this.updateSchema()
		}
		const errLabel = (error||[])[0] ? <label style={{color:"red"}}>{error[0]}</label> : '';

		return (
			<Fragment>
				{
					isVisible &&
					(
						<div className="outer-edit-container" onClick={(ifSuccess === true) ? handleClickToEdit({}, null, "", true) : e => handleClickToEdit(e, null, "")} >
							<div className="edit-form-container tac">
								<div className="edit-form">
									<span className="closeIcon" aria-hidden="true" onClick={e => handleClickToEdit(e, null, "")}>×</span>
									<p className="tal popup-header" key="header">{editMode ? t('editHeader') : t('addHeader')}</p>
										<Form
											visibleHints={editMode ? true : false}
					            formData={formData}
											backgroundDark={true}
					            onChange={onChange}
	                  	schema={this.state.schema}/>
			          	<div className="img-upload-container">
				            <div>
				          		<input type="file" name="file" id="file" className="inputfile" onChange={imageSelectedHandler} />
											<label htmlFor="file" className="m0">{t('uploadImage')}</label>
										</div>
										<label className="uploading-image-name">{(selectedImage||{}).name||t('pictureIsObligatory')}</label>
	          			</div>
									{errLabel}
									<div className="flex options-container">
										<label className="tal flex-grow-help">{t('help')}</label>
										<Button title={disabled ? (editMode ? 'Pro aktualizaci je nutne zmenit nejake policko' : 'Pro vytvoreni drinku je potreba vyplnit vsechna policka') : t('save')} disabled={disabled} onClick={() => action()} style={disabled ? {opacity: 0.5, backgroundColor: "#FE2183", fontSize: 14} : {backgroundColor: "#FE2183", fontSize: 14}}>{t('save')}</Button>
									</div>
								</div>
							</div>
						</div>
					)
				}
			</Fragment>
		);
	}
}

export default translate('editOrAddBusinessDrinkPop')(EditOrAddBusinessDrinkPop);
