import request from '../../utils/request';
import announceSuccess from './successAction';
import errorAction from './errorAction'

export const CURRENT_USER_RECEIVED = 'CURRENT_USER_RECEIVED';
export const USER_ID_RECEIVED = 'USER_ID_RECEIVED';

export const userReceivedAction = (user) => {
  return {
    type: CURRENT_USER_RECEIVED,
    user: user,
  };
}

export const getCurrentUser = () => {
  return (dispatch) => {
    request('/user/current/', 'GET' )
    .then(r => dispatch(userReceivedAction(r)))
    .catch(e => {})
  }
}

export const userIdReceivedAction = (userID) => {
  return {
    type: USER_ID_RECEIVED,
    user: userID,
  };
}

export const getUserId = (id) => {
  return (dispatch) => {
    request('/user/'+id+'/', 'GET' )
    .then(r => dispatch(userIdReceivedAction(r)))
    .catch(e => {})
  }
}

export const updateClientById = (id,r) => {
  return (dispatch) => {
    request('/user/client/'+id+'/', 'PATCH',{is_trustworthy:r} )
    .catch(e => {})
  }
}

export const userPasswordResetAction = (email, actionId) => {
  return (dispatch) => {
    request('/user/password-reset/', 'POST', {email})
    .then(()=>dispatch(announceSuccess(actionId)))
    .catch(e => e.then(err=>dispatch(errorAction(err))))
    .catch(e => {})
  }
}

export const userPasswordChangeAction = (oldPassword, password, actionId) => {
  return (dispatch) => {
    request('/user/password-change/', 'POST', {old_password: oldPassword, password})
    .then(() => dispatch(getCurrentUser()))
    .then(()=>dispatch(announceSuccess(actionId)))
    .catch(e => e.then(err=>dispatch(errorAction(err))))
    .catch(e => {})
  }
}

export default {
  getCurrentUser,
  getUserId,
  updateClientById,
  userPasswordResetAction,
  userPasswordChangeAction,
}
