import React, { Component, Fragment } from 'react';
import { translate } from 'react-i18next';
import BusinessImagesCarousel from  '../businessImagesCarousel/BusinessImagesCarousel';
import BigDrinkLabel from '../bigDrinkLabel/BigDrinkLabel';

const days = ['monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday']

class BusinessDetail extends Component {
  constructor(props) {
    super(props);
    this.state = { openingHoursDetailVisible: false }
  }
  handleClickToShow = () => {
		this.setState({ openingHoursDetailVisible: !this.state.openingHoursDetailVisible })
	}
  businessToday = (openingHours) => {
    const { t } = this.props;
    if (!openingHours || openingHours.length < 1)
      return <label>{(t('todayIsClosed'))}</label>
    let date = new Date()
    let dayInWeek = (date.getDay() - 1 < 0) ? 6 : date.getDay() - 1;
    if (openingHours[dayInWeek] === null)
      return <label>{(t('todayIsClosed'))}</label>
    return <label>{`${t('today')} ${openingHours[dayInWeek]}`}</label>
  }
  render() {
    const { handleClickToShow, business, t } = this.props;
    const drinkCards = business.drinks.map((drink, index) => {
        return <BigDrinkLabel key={index} drink={drink} />
    });
    let openingHours;
    if (business.openingHours && business.openingHours.length > 0) {
      openingHours = business.openingHours.map((day, index) => {
        return (
          <div key={index} style={{margin: 0, padding: 0, fontSize: 14, fontWeight: 300, marginLeft: 180}}>
            <label style={{width: 30, margin: 0, padding: 0}}>{`${t(days[index])}:`}</label>
            <label style={{margin: 0, padding: 0}}>{day===null ? t('closed') : `${(day||"").toString().substring(0,5)} - ${(day||"").toString().substring(6,11)}`}</label>
          </div>
          )
      })
    }
    return (
      <div className="business-detail-content">
        <span className="closeIcon" aria-hidden="true" onClick={e => handleClickToShow(null, e)}>×</span>
        <div className="m20">
          <h1 className="business-detail-header tal upper">{business.barName}</h1>
          <label className="business-detail-description">{business.description}</label>
          <div className="business-detail-info-container-light">
            <label id="address-header" className="business-detail-info-header">{t('address')}</label><label className="business-detail-info-value">{`${business.street} ${business.houseNo}, ${business.town}, ${business.zipCode.toString().substring(0,3)} ${business.zipCode.toString().substring(3,5)}`}</label>
          </div>
          { (business.openingHours && business.openingHours.length > 0) &&
            <Fragment>
              <div className="business-detail-info-container-dark" onClick={()=>this.handleClickToShow()}>
                <label className="business-detail-info-header">{t('openHours')}</label>
                <label className="business-detail-info-value">{this.businessToday(business.openingHours)}</label>
                <span className="business-detail-opening-hours-icon">
                  { this.state.openingHoursDetailVisible ?
                    <i className="fas fa-chevron-circle-up"></i>
                  :
                    <i className="fas fa-chevron-circle-down"></i>
                  }
                </span>
              </div>
              <div className="business-detail-info-container-dark-opening-hours">
                { this.state.openingHoursDetailVisible &&
                (<label className="business-detail-opening-hours-detail">
                  {openingHours}
                  {/*<p style={{margin: 0, padding: 0, fontSize: 12, fontWeight: 300, marginBottom: 10, marginTop: 10, marginLeft: 120}}>{t('warning')}</p>*/}
                </label>) }
              </div>
            </Fragment>
          }
          { (business.website && business.website !== '' && business.website.length > 1) &&
            <div className="business-detail-info-container-light">
              <label id="website-header" className="business-detail-info-header">{t('website')}</label><a href={business.website} className="business-detail-info-value-link" target="_blank" rel="noopener noreferrer">{business.website}</a>
            </div>
          }
          {/*<div className="business-detail-info-container-light">
            <label className="business-detail-info-header">{t('contact')}</label><label className="business-detail-info-value">{`${business.contactName} ${business.contactPhone}`}</label>
          </div>*/}
          { (business.images.length !== 0) &&
            (<div className="business-detail-images-container">
              <div className="business-detail-images">
                <BusinessImagesCarousel images={business.images} />
              </div>
            </div>)
          }
          { (business.drinks.length !== 0) &&
            (<div className="business-detail-drinks">
              <h3 className="tal business-detail-drinks-header">{t('drinks')}</h3>
                <div className="drink-cards-container">
                    {drinkCards}
                </div>
            </div>)
          }
        </div>
      </div>
    );
  }
}

export default translate('businessDetail')(BusinessDetail);
