import request from '../../utils/request';

export const BUSINESS_LIST_RECEIVED = 'BUSINESS_LIST_RECEIVED';

export const businessListReceivedAction = (businessList) => {
  return {
    type: BUSINESS_LIST_RECEIVED,
    businessList: businessList,
  };
}

export default () => {
  return (dispatch) => {
    request('/user/business/list/', 'get')
    .then(r => dispatch(businessListReceivedAction(r)))
    .catch(e=>{})
  }
}
