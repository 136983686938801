import React, { Component, Fragment } from 'react';

import { translate } from 'react-i18next';
import TopBarContainer from './../../../components/topBar/TopBarContainer';
import BusinessLabelContainer from '../../../components/businessLabel/BusinessLabelContainer';
import BusinessProfile from '../../../components/businessProfile/BusinessProfile';
import BusinessDrinks from '../../../components/businessDrinks/BusinessDrinks';
import EditOrAddBusinessDrinkPopContainer from '../../../components/editOrAddBusinessDrinkPop/EditOrAddBusinessDrinkPopContainer';
import EditBusinessProfilePopContainer from '../../../components/editBusinessProfilePop/EditBusinessProfilePopContainer';
import UploadBusinessImagePopContainer from '../../../components/uploadBusinessImagePop/UploadBusinessImagePopContainer';
import ChangePasswordPopContainer from '../../../components/changePasswordPop/ChangePasswordPopContainer';
import DeleteConfirmationPopupContainer from '../../../components/deleteConfirmationPopup/DeleteConfirmationPopupContainer';
import Footer from '../../../components/footer/Footer'

class BusinessPage extends Component {
	constructor(props) {
		super(props);
		this.state = {
			isBusinessProfileActive: true,
			isBusinessDrinksActive: false,
			isEditBusinessProfileFormVisible: false,
			isEditBusinessDrinkFormVisible: false,
			isUploadBusinessImageFormVisible: false,
			isChangePasswordFormVisible: false,
			isDeleteConfirmationFormVisible: false,
			businessId: null,
			deleteOperation: null,
			deleteId: null,
			drink: {},
			business: '',
		};
	}
	handleClickBusinessProfile = () => {
		this.setState({isBusinessProfileActive: true, isBusinessDrinksActive: false});
	}
	handleClickBusinessDrinks = () => {
		this.setState({isBusinessProfileActive: false, isBusinessDrinksActive: true});
	}
	handleChangeOfDrinkName = (value) => {
		this.setState({"name": {value} });
	}
	handleClickToEditBusinessProfile = (e, businessId, business, ifSuccess = false) => {
		if (/*e.target.className === "btn-style" ||*/ (e.target||{}).className === "table-edit-icon icon" || (e.target||{}).className === "outer-edit-profile-container" || (e.target||{}).className === "closeIcon" || (e.target||{}).nodeName === "svg" || ifSuccess) {
			if (!this.state.isEditBusinessProfileFormVisible) {
				this.setState({isEditBusinessProfileFormVisible: true, businessId: businessId, business: business});
			} else {
				this.setState({isEditBusinessProfileFormVisible: false, businessId: null, business: ''});
				if (ifSuccess) {
					const { t } = this.props;
					alert(t('editingBusinessProfileSuccessful'))
				}
			}
		}
	}
	handleClickToEditBusinessDrink = (e, businessId, drink, ifSuccess = false) => {
		if ((e.target||{}).id === "addDrink" || (e.target||{}).className === "outer-edit-container" || (e.target||{}).className === "table-edit-icon icon" || (e.target||{}).className === "closeIcon" || (e.target||{}).nodeName === "svg" || ifSuccess) {
			if (!this.state.isEditBusinessDrinkFormVisible) {
				this.setState({isEditBusinessDrinkFormVisible: true, businessId: businessId, drink: drink});
			} else {
				this.setState({isEditBusinessDrinkFormVisible: false, businessId: null, drink: {}});
				if (ifSuccess) {
					const { t } = this.props;
					alert(t('editingBusinessDrinkSuccessful'))
				}
			}
		}
	}
	handleClickToUploadBusinessImage = (e, businessId, ifSuccess = false) => {
		if ((e.target||{}).id === "uploadPhoto" || (e.target||{}).className === "closeIcon" || (e.target||{}).className === "outer-upload-container" || ifSuccess ) {
			if (!this.state.isUploadBusinessImageFormVisible) {
				this.setState({isUploadBusinessImageFormVisible: true, businessId: businessId});
			} else {
				this.setState({isUploadBusinessImageFormVisible: false, businessId: null});
				if (ifSuccess) {
					const { t } = this.props;
					alert(t('ulpoadingBusinessImageSuccessful'))
				}
			}
		}
	}
	handleClickToChangePassword = (e, ifSuccess = false) => {
		if ((e.target||{}).id === "changePassword" || (e.target||{}).className === "outer-change-container" || (e.target||{}).className === "closeIcon" ||  ifSuccess) {
			if (!this.state.isChangePasswordFormVisible) {
				this.setState({isChangePasswordFormVisible: true});
			} else {
				this.setState({isChangePasswordFormVisible: false});
				if (ifSuccess) {
					const { t } = this.props;
					alert(t('changingPasswordSuccessful'))
				}
			}
		}
	}
	handleClickToConfirm = (e, deleteOperation, deleteId, ifSuccess = false) => {
		if ((e.target||{}).nodeName === "svg" || (e.target||{}).className === "outer-delete-container" || (e.target||{}).className === "closeIcon" || (e.target||{}).id === "cancel-delete" || ifSuccess) {
			if (!this.state.isDeleteConfirmationFormVisible) {
				this.setState({isDeleteConfirmationFormVisible: true, deleteOperation: deleteOperation, deleteId: deleteId});
			} else {
				this.setState({isDeleteConfirmationFormVisible: false, deleteOperation: null, deleteId: null});
				if (ifSuccess) {
					const { t } = this.props;
					alert(t('deletingWasSuccessful'))
				}
			}
		}
	}
	render() {
		const { isBusinessProfileActive, isBusinessDrinksActive, isEditBusinessProfileFormVisible, isEditBusinessDrinkFormVisible, businessId, drink, business, isUploadBusinessImageFormVisible, isChangePasswordFormVisible, isDeleteConfirmationFormVisible, deleteOperation, deleteId } = this.state;
		return (
			<Fragment>
				<div className="page-content-container">
					<div style={{minWidth: 560}}>
						<TopBarContainer topbarStyle="business" />
					</div>
					{/*<div className="container pt40">
						<div className="row">*/}
					<div className="business-container">
							{/*<div className="col-sm-4">*/}
							<div className="business-col-sm-4">
								<BusinessLabelContainer
									onClickBusinessProfile={this.handleClickBusinessProfile}
									onClickBusinessDrinks={this.handleClickBusinessDrinks}
									isBusinessProfileActive={isBusinessProfileActive}
									isBusinessDrinksActive={isBusinessDrinksActive} />
							</div>
							<div className="business-col-sm-8">
							{/*<div className="col-sm-8">*/}
								{ isBusinessProfileActive && <BusinessProfile handleChangeOfDrinkName={this.handleChangeOfDrinkName} handleClickToEdit={this.handleClickToEditBusinessProfile} handleClickToUpload={this.handleClickToUploadBusinessImage} handleClickToChange={this.handleClickToChangePassword} handleClickToConfirm={this.handleClickToConfirm} /> }
								{ isBusinessDrinksActive && <BusinessDrinks handleChangeOfDrinkName={this.handleChangeOfDrinkName} handleClickToEdit={this.handleClickToEditBusinessDrink} handleClickToConfirm={this.handleClickToConfirm} /> }
							</div>
						{/*</div>*/}
					</div>
					{/* Updating popups */}
					{ isEditBusinessProfileFormVisible &&
						<EditBusinessProfilePopContainer
							isVisible={isEditBusinessProfileFormVisible}
							handleClickToEdit={this.handleClickToEditBusinessProfile}
							businessId={businessId}
							business={business} />
					}
					{ isEditBusinessDrinkFormVisible &&
						<EditOrAddBusinessDrinkPopContainer
							isVisible={isEditBusinessDrinkFormVisible}
							handleClickToEdit={this.handleClickToEditBusinessDrink}
							businessId={businessId}
							drink={drink} />
					}
					{ isUploadBusinessImageFormVisible &&
						<UploadBusinessImagePopContainer
							isVisible={isUploadBusinessImageFormVisible}
							handleClickToUpload={this.handleClickToUploadBusinessImage}
							businessId={businessId} />
					}
					{ isChangePasswordFormVisible &&
						<ChangePasswordPopContainer
							isVisible={isChangePasswordFormVisible}
							handleClickToChange={this.handleClickToChangePassword} />
					}
					{ isDeleteConfirmationFormVisible &&
						<DeleteConfirmationPopupContainer
							isVisible={isDeleteConfirmationFormVisible}
							handleClickToConfirm={this.handleClickToConfirm}
							deleteOperation={deleteOperation}
							deleteId={deleteId} />
					}
				</div>
				<div style={{minWidth: 580}}>
					<Footer />
				</div>
			</Fragment>
		);
	}
}

export default translate('businessPage')(BusinessPage);
