import React, {Component} from 'react';
import { translate } from 'react-i18next';

class DrinkLabel extends Component {
	render() {
		const { image, name, description, price, t } = this.props;
		return (
			<div key="drink-container" className="drink-container">
				<div key="drink-image" className="drink-image">
					<img src={image} width="100%" height="100%" alt="" style={{borderRadius: "10px 0 0 10px", objectFit: 'cover', objectPosition: 'center'}}/>
				</div>
				<div key="rectangle" className="rectangle"></div>
				<div key="drink-info-container" className="drink-info-container">
					<label key="name" className="name upper">{name}</label>
					<div className="flex">
						<label key="description" className="description">{description}</label>
						<label key="price" className="price">{`${price} ${t('currency')}`}</label>
					</div>
				</div>
			</div>
		);
	}
}

export default translate('drinkLabel')(DrinkLabel);
