import React, { Component } from 'react';
import { connect } from 'react-redux';
import UploadBusinessImagePop from './UploadBusinessImagePop';
import { uploadBusinessImage } from '../../store/actions/businessProfileAction';

class UploadBusinessImagePopContainer extends Component {
	constructor (props) {
	    super(props);
	    const {businessId} = this.props;
	    this.state = {
		    isTitle: false,
				selectedImage:null,
				business: businessId,
				ifSuccess: null,
		 	}
		}

		uploadImage = () => {
			const actionId =  Math.random();
	    this.props.upload(this.state, actionId);
	    this.setState(prevState => ({
	      ifSuccess: prevState.ifSuccess===null ? actionId : null,
	    }))
		}

		imageSelectedHandler = (event) => {
	    this.setState({selectedImage:event.target.files[0]})
  	}

  	render() {
    	const { isVisible, handleClickToUpload, success } = this.props;
    	const { selectedImage, ifSuccess } = this.state;
			return (
				<UploadBusinessImagePop
						imageSelectedHandler={this.imageSelectedHandler}
		        selectedImage={selectedImage}
						formData={this.state}
						ifSuccess={ifSuccess===success}
						onChange={v=>this.setState(v)}
						disabled={selectedImage === null}
						action={this.uploadImage}
						isVisible={isVisible}
						handleClickToUpload={handleClickToUpload} />
			);
  	}
}

function mapStateToProps(state) {
  return {
    success:state.success,
  }
}

function mapDispatchToProps(dispatch) {
  	return {
      	upload: (uploadData, actionId) => dispatch(uploadBusinessImage(uploadData, actionId)),
  	}
}

export default connect(mapStateToProps, mapDispatchToProps)(UploadBusinessImagePopContainer);
