import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import { store } from './store/store';

export const languages = ["cs", "en"]

i18n.use(LanguageDetector).init({
	// we init with resources
	resources: {
		cs: {
			topBar: {
				"appName": "Drinkee",
				"howItWorks": "Jak to funguje?",
				"connectYourBar": "Zapoj svůj bar",
				"blog": "Blog",
				"catalog": "Katalog podniků",
				"contact": "Kontaktujte nás!",
				"register": "Registrovat nyní",
				"login": "Přihlásit se",
				"downloadTheApp": "Stáhnout appku",
				"logout": "Odhlásit se",
				"changePassword": "Změňit si heslo",
				"profile": "Profil",
				"changeLanguage": "Změnit jazyk",
			},
			catalog: {
				"catalog": "Katalog podniků",
			},
			drinkLabel: {
				"currency": "Kč",
			},
			businessLabel: {
				"drinksOverview": "Přehled drinků",
				"businessProfile": "Profil podniku",
			},
			imagesOverview: {
				"uploadPhoto": "Nahrát fotku",
				"companyPics": "Obrázky podniku",
				"drinksPics": "Obrázky drinků",
			},
			businessDrinksTable: {
				"drinksOverview": "Přehled drinků",
				"drinksComposition": "Složení drinků",
				"drinksPrice": "Cena drinků",
				"approved": "Schváleno",
				"sale": "Akce",
				"addDrink": "+ Přidat drink",
				"edit": "Upravit",
				"delete": "Smazat",
				"currency": "Kč",
			},
			businessProfileTable: {
				"companyInfo": "Údaje podniku",
				"companyName": "Název podniku",
				"companyDescription": "Popis vašeho podniku by měl být co nejvíce výstižný a účelný",
				"type": "Zařazení vašeho podniku (Sportbar, club, bar, atd.)",
				"address": "Adresa: ",
				"contactName": "Jméno a příjmení:",
				"contactPhone": "Kontaktní telefon:",
				"contactEmail": "Kontaktní email:",
				"barName": "Jméno podniku:",
				"ico": "IČO podniku:",
				"gps": "GPS souřadnice:",
				"description": "Popis podniku:",
				"description_cs": "Popis podniku (CZ):",
				"description_en": "Popis podniku (EN):",
				"changePassword": "Změnit si heslo",
				"open": "Otevírací doba: ",
				"days": "Pondělí-Neděle",
				"monday": "Po",
				"tuesday": "Út",
				"wednesday": "St",
				"thursday": "Čt",
				"friday": "Pá",
				"saturday": "So",
				"sunday": "Ne",
				"closed": "Zavřeno",
				"website": "Webová stránka:",
			},
			registrationBusinessForm: {
				"appName": "Drinkee",
				"contactName": "Jméno a příjmení:",
				"email": "E-mail:",
				"contactPhone": "Kontaktní telefon:",
				"contactEmail": "Kontaktní email:",
				"barName": "Jméno podniku:",
				"ico": "IČO podniku:",
				"town": "Město:",
				"street": "Ulice:",
				"houseNo": "Číslo domu:",
				"zipCode": "PSČ:",
				"gps": "GPS souřadnice:",
				"description": "Popis podniku:",
				"description_cs": "Popis podniku (CZ):",
				"description_en": "Popis podniku (EN):",
				"opening": "Otevřeno od: ",
				"closing": "Otevřeno do: ",
				"password": "Heslo:",
				"passwordAgain": "Heslo znovu:",
				"registerHint": "Pro registraci musíte vyplnit všechna políčka",
				"register": "Registrovat se",
				"businessConditions": "Zásady ochrany osobních údajů",
				"alreadyAccount": "Již mám účet",
				"registerYourBusiness": "Registrujte svůj podnik",
				"successfulRegistration": "Registrace proběhla v pořádku",
			},
			loginBusinessForm: {
				"appName": "Drinkee",
				"logInYourBusiness": "Přihlášení",
				"email": "E-mail:",
				"password": "Heslo:",
				"logIn": "Přihlásit se",
				"register": "Ještě nemáte účet?",
				"forgottenPassword": "Zapomněli jste si heslo?",
			},
			editOrAddBusinessDrinkPop: {
				"editHeader": "Upravte drink",
				"addHeader": "Vytvořte nový drink",
				"name": "Název drinku:",
				"composition": "Složení drinku:",
				"composition_en": "Složení (EN):",
				"composition_cs": "Složení (CZ):",
				"name_cs": "Název (CZ):",
				"name_en": "Název (EN):",
				"price": "Cena drinku:",
				"image": "Fotka drinku:",
				"uploadImage": "Nahrát fotku",
				"help": "*Veškeré změny musejí projít schválením, proto se neprojeví v aplikaci ihned",
				"save": "Uložit změny",
				"pictureIsObligatory": "Obrázek je povinný",
			},
			editBusinessProfilePop: {
				"header": "Upravit",
				"contactName": "Jméno a příjmení:",
				"contactPhone": "Kontaktní telefon:",
				"contactEmail": "Kontaktní email:",
				"barName": "Jméno podniku:",
				"ico": "IČO podniku:",
				"town": "Město:",
				"street": "Ulice:",
				"houseNo": "Číslo domu:",
				"zipCode": "PSČ:",
				"openingHoursError": "Zadejte prosím otevírací dobu ve tvaru HH:MM-HH:MM",
				"gps": "GPS souřadnice:",
				"description": "Popis podniku:",
				"description_cs": "Popis podniku (CZ):",
				"description_en": "Popis podniku (EN):",
				"opening": "Otevřeno od: ",
				"closing": "Otevřeno do: ",
				"help": "*Veškeré změny musejí projít schválením, proto se neprojeví v aplikaci ihned",
				"save": "Uložit změny",
				"mondayOpeningHours": "Otevírací doba v Po:",
				"tuesdayOpeningHours": "Otevírací doba v Út:",
				"wednesdayOpeningHours": "Otevírací doba ve St:",
				"thursdayOpeningHours": "Otevírací doba v Čt:",
				"fridayOpeningHours": "Otevírací doba v Pá:",
				"saturdayOpeningHours": "Otevírací doba v So:",
				"sundayOpeningHours": "Otevírací doba v Ne:",
				"website": "Webová stránka:",
			},
			image: {
				"setAsProfilePicture": "Nastavit jako profilovou fotku",
				"deletePicture": "Odstranit fotku",
			},
			forgottenPasswordForm: {
				"appName": "Drinkee",
				"email": "E-mail:",
				"forgottenPassword": "Zapomenuté heslo",
				"sendAnEmail": "Poslat e-mail",
				"hint": "Na Vámi zadaný e-mail odešleme instrukce, jak pokračovat dále",
				"back": "Zpět",
				"emailSended": "Byl Vám zaslán email s intrukcemi, jak pokračovat",
			},
			drinkOverview: {
				"price": "Cena:",
				"nameCs": "Název (CZ):",
				"nameEn": "Název (EN):",
				"composition": "Složení:",
				"compositionEn": "Složení (EN):",
				"compositionCs": "Složení (CZ):",
				"approved": "Schváleno:",
				"currency": "Kč",
			},
			uploadBusinessImagePop: {
				"header": "Nahrát obrázek podniku",
				"isTitle": "Má být obrázek na profilu?",
				"upload": "Nahrát",
				"chooseImage": "Vybrat obrázek",
			},
			changePasswordPop: {
				"changeYourPassword": "Změňte si heslo",
				"oldPassword": "Staré heslo:",
				"newPassword": "Nové heslo:",
				"newPasswordAgain": "Nové heslo znovu:",
				"changePassword": "Změnit",
			},
			deleteConfirmationPopup: {
				"header": "Opravdu si toto přejete smazat?",
				"delete": "Smazat",
				"cancel": "Zrušit",
			},
			businessDetail: {
				"description": "Popis:",
				"address": "Adresa:",
				"openHours": "Otevírací doba: ",
				"days": "Pondělí - Neděle",
				"images": "Obrázky",
				"drinks": "Naše drinky",
				"contact": "Kontakt:",
				"contactName": "Kontaktní osoba:",
				"contactPhone": "Kontaktní telefon:",
				"monday": "Po",
				"tuesday": "Út",
				"wednesday": "St",
				"thursday": "Čt",
				"friday": "Pá",
				"saturday": "So",
				"sunday": "Ne",
				"closed": "Zavřeno",
				"warning": "*Otvírací doba se může lišit o státních svátcích.",
				"todayIsClosed": "Dnes je zavřeno",
				"today": "Dnes: ",
				"website": "Webová stránka:",
			},
			drinkCard: {
				"drinkName": "Název drinku:",
				"drinkComposition": "Složení:",
				"drinkPrice": "Cena:",
				"currency": "Kč",
			},
			businessPage: {
				"editingBusinessProfileSuccessful": "Úpravy profilu se uložily",
				"editingBusinessDrinkSuccessful": "Úpravy se uložily, změny se projeví až po schválení",
				"changingPasswordSuccessful": "Heslo úspěšně změněno",
				"ulpoadingBusinessImageSuccessful": "Nahrání obrázku proběhlo v pořádku",
				"deletingWasSuccessful": "Objekt úspěšně smazán",
 			},
			bigDrinkLabel: {
				"drinkName": "Název drinku:",
				"drinkComposition": "Složení:",
				"drinkPrice": "Ušetříš:",
				"currency": "Kč",
			},
			editBusinessProfilePopContainer: {
				"closed": "Zavřeno",
			},
			afterPayment: {
				"acknowledgment": "Vaše platba proběhla v pořádku. Děkujeme",
				"backToCustomerPage": "Zpět na stránku s předplatným",
				"paymentCanceled": "Vaše platba byla bohužel zrušena, prosím zkuste to ještě jednou, nebo nás neváhejte kontaktovat na podpora@freelyapp.cz",
				"paymentTimeouted": "Vypršel časový limit pro platbu, zkuste prosím ještě jednou.",
			},
			customer: {
				"subscriptionInfo": "Váš tarif je platný do:",
				"orderSummaryInfo": "Prodloužení/zakoupení - shrnutí objednávky",
				"subscription": "Vaše mesíční předplatné",
				"cancel": "Zrušit",
				"price": "Cena předplatného na 1 měsíc:",
				"pay": "Zaplatit",
				"buy": "Předplaťte",
				"extend": "Prodlužte",
				"promo": " si členství Freely a získejte každý den v měsíci drink zdarma",
				"promo2": "za cenu jednoho drinku jich díky členství Freely získáte až 31. Objevte s námi nová místa a bavte se!",
				"paymentInfo": "*Pro vaše pohodlí se měsíční členství automaticky před jeho vypršením prodlužuje.",
				"currency": "Kč",
				"subscriptionNotValid": "Nemáte aktivní členství",
				"repeatingPayment": "*Opakovanou platbu je možné kdykoliv zrušit v osobním nastavení aplikace.",
				"terms1a": "Souhlasím s ",
				"terms": "obchodními podmínkami",
				"terms1b": " a se založením opakované platby.",
				"youMustAgree": "*Musíte odsouhlasit",
				"membershipCanceled": "Předplatné úspěšně zrušeno.",
				"close": "Zavřít",
				"cancelMembership": "Zrušit předplatné",
				"header": "Opravdu si přejete zrušit členství?",
			},
			footer: {
				"createdBy": "Vytvořilo",
			},
		},
		en: {
			topBar: {
				"appName": "Drinkee",
				"howItWorks": "How it works?",
				"connectYourBar": "Connect your bar",
				"blog": "Blog",
				"catalog": "Businesses catalog",
				"contact": "Contact us!",
				"register": "Register now",
				"login": "Log in",
				"downloadTheApp": "Download the app",
				"logout": "Log out",
				"changePassword": "Change password",
				"profile": "Profile",
				"changeLanguage": "Change language",
			},
			catalog: {
				"catalog": "Businesses catalog",
			},
			drinkLabel: {
				"currency": "Kč",
			},
			businessLabel: {
				"drinksOverview": "Drinks overview",
				"businessProfile": "Business profile",
			},
			imagesOverview: {
				"uploadPhoto": "Upload an image",
				"companyPics": "Business images",
				"drinksPics": "Drinks images",
			},
			businessDrinksTable: {
				"drinksOverview": "Drink overview",
				"drinksComposition": "Drinks composition",
				"drinksPrice": "Drink prices",
				"approved": "Approved",
				"sale": "Action",
				"addDrink": "+ Add a drink",
				"edit": "Edit",
				"delete": "Delete",
				"currency": "Kč",
			},
			businessProfileTable: {
				"companyInfo": "Business data",
				"companyName": "Business name",
				"companyDescription": "Decription of your business should be as detailed as it can be",
				"type": "Type of your business (Sportbar, club, bar, etc.)",
				"address": "Adrress: ",
				"contactName": "Contact name:",
				"contactPhone": "Contact phone:",
				"contactEmail": "Contact email:",
				"barName": "Business name:",
				"ico": "Business ID:",
				"gps": "GPS coordinates:",
				"description": "Description:",
				"description_cs": "Description (CZ):",
				"description_en": "Description (EN):",
				"changePassword": "Change password",
				"open": "Open hours: ",
				"days": "Monday-Sunday",
				"monday": "Mo",
				"tuesday": "Tu",
				"wednesday": "We",
				"thursday": "Th",
				"friday": "Fr",
				"saturday": "Sa",
				"sunday": "Su",
				"closed": "Closed",
				"website": "Website:",
			},
			registrationBusinessForm: {
				"appName": "Drinkee",
				"contactName": "Contact name:",
				"email": "E-mail:",
				"contactPhone": "Contact phone:",
				"contactEmail": "Contact email:",
				"barName": "Business name:",
				"ico": "Business ID:",
				"town": "City:",
				"street": "Street:",
				"houseNo": "House number:",
				"zipCode": "Postcode:",
				"gps": "GPS coordinates:",
				"description": "Business description:",
				"description_cs": "Description (CZ):",
				"description_en": "Description (EN):",
				"opening": "Opens at: ",
				"closing": "Closes at: ",
				"password": "Password:",
				"passwordAgain": "Password again:",
				"register": "Register",
				"registerHint": "To be able to register you must fill every input",
				"businessConditions": "Data privacy policy",
				"alreadyAccount": "Already have an account",
				"registerYourBusiness": "Register your business",
				"successfulRegistration": "Registration was successful",
			},
			loginBusinessForm: {
				"appName": "Drinkee",
				"logInYourBusiness": "Login",
				"email": "E-mail:",
				"password": "Password:",
				"logIn": "Log in",
				"register": "Not registered?",
				"forgottenPassword": "Did you forget your password?",
			},
			editOrAddBusinessDrinkPop: {
				"editHeader": "Edit a drink",
				"addHeader": "Create a new drink",
				"name": "Drink name:",
				"composition": "Composition:",
				"composition_en": "Composition (EN):",
				"composition_cs": "Composition (CZ):",
				"price": "Drink price:",
				"image": "Drink image:",
				"uploadImage": "Upload an image",
				"help": "*Unless all changes are approved none of them will appeared",
				"save": "Save changes",
				"name_cs": "Name (CZ):",
				"name_en": "Name (EN):",
				"pictureIsObligatory": "Picture is obligatory",
			},
			editBusinessProfilePop: {
				"header": "Edit",
				"contactName": "Contact name:",
				"contactPhone": "Contact phone:",
				"contactEmail": "Contact email:",
				"barName": "Business name:",
				"ico": "Business ID:",
				"town": "City:",
				"street": "Street:",
				"houseNo": "House number:",
				"zipCode": "Postcode:",
				"openingHoursError": "Please type opening hours in form like this HH:MM-HH:MM",
				"gps": "GPS coordinates:",
				"description": "Business description:",
				"description_cs": "Description (CZ):",
				"description_en": "Description (EN):",
				"opening": "Opens at: ",
				"closing": "Closes at: ",
				"help": "*Unless all changes are approved none of them will appeared",
				"save": "Save changes",
				"mondayOpeningHours": "Opening hours at Mo:",
				"tuesdayOpeningHours": "Opening hours at Tu:",
				"wednesdayOpeningHours": "Opening hours at We:",
				"thursdayOpeningHours": "Opening hours at Th:",
				"fridayOpeningHours": "Opening hours at Fr:",
				"saturdayOpeningHours": "Opening hours at Sa:",
				"sundayOpeningHours": "Opening hours at Su:",
				"website": "Website:",
			},
			image: {
				"setAsProfilePicture": "Set as a profile picture",
				"deletePicture": "Delete picture",
			},
			forgottenPasswordForm: {
				"appName": "Drinkee",
				"email": "E-mail:",
				"forgottenPassword": "Forgotten password",
				"sendAnEmail": "Send an email",
				"hint": "You will be sent an email with instruction how to continue",
				"back": "Back",
				"emailSended": "You were sent an email with instructions how to continue",
			},
			drinkOverview: {
				"price": "Price:",
				"nameCs": "Name (CZ):",
				"nameEn": "Name (EN):",
				"composition": "Composition:",
				"composition_en": "Composition (EN):",
				"composition_cs": "Composition (CZ):",
				"approved": "Approved:",
				"currency": "Kč",
			},
			uploadBusinessImagePop: {
				"header": "Upload a business image",
				"isTitle": "Is this image supposed to be a profile picture?",
				"upload": "Upload",
				"chooseImage": "Choose an image",
			},
			changePasswordPop: {
				"changeYourPassword": "Change your password",
				"oldPassword": "Old password:",
				"newPassword": "New password:",
				"newPasswordAgain": "New password again:",
				"changePassword": "Change",
			},
			deleteConfirmationPopup: {
				"header": "Do you really wish to delete this?",
				"delete": "Delete",
				"cancel": "Cancel",
			},
			businessDetail: {
				"description": "Description:",
				"address": "Address:",
				"openHours": "Open hours: ",
				"days": "Monday - Sunday",
				"images": "Images",
				"drinks": "Our drinks",
				"contact": "Contact:",
				"contactName": "Contact person:",
				"contactPhone": "Contact phone:",
				"monday": "Mo",
				"tuesday": "Tu",
				"wednesday": "We",
				"thursday": "Th",
				"friday": "Fr",
				"saturday": "Sa",
				"sunday": "Su",
				"closed": "Closed",
				"warning": "*Opening hours can be different on holidays.",
				"todayIsClosed": "Today is closed",
				"today": "Today: ",
				"website": "Website:",
			},
			drinkCard: {
				"drinkName": "Drink name:",
				"drinkComposition": "Composition:",
				"drinkPrice": "Price:",
				"currency": "Kč",
			},
			businessPage: {
				"editingBusinessProfileSuccessful": "Editing your profile was successful",
				"editingBusinessDrinkSuccessful": "Your changes were saved, they will be visible after they are approved",
				"changingPasswordSuccessful": "Your password was changed",
				"ulpoadingBusinessImageSuccessful": "Uploading was successful",
				"deletingWasSuccessful": "Deleting was successful",
			},
			bigDrinkLabel: {
				"drinkName": "Drink name:",
				"drinkComposition": "Composition:",
				"drinkPrice": "You save:",
				"currency": "Kč",
			},
			editBusinessProfilePopContainer: {
				"closed": "Closed",
			},
			afterPayment: {
				"acknowledgment": "Your payment was accepted. Thank you",
				"backToCustomerPage": "Return to subscription page",
				"paymentCanceled": "Your payment was canceled. Please try again or contact support@freelyapp.cz",
				"paymentTimeouted": "Time limit for your payment is over. Please try again",
			},
			customer: {
				"subscriptionInfo": "Your tariff is valid to:",
				"orderSummaryInfo": "Extension/payment - order summary",
				"subscription": "Your monthly subscription",
				"cancel": "Cancel",
				"price": "Subscription price for one month:",
				"pay": "Pay",
				"buy": "Buy",
				"extend": "Extend",
				"promo": " our subscription and get one free drink every day",
				"promo2": "with Freely you get up to 31 drinks for the price of just one. Discover new places and have fun!",
				"paymentInfo": "*For your convenience, monthly membership is extended automatically before its expiration.",
				"currency": "Kč",
				"subscriptionNotValid": "You do not have an active subscription",
				"repeatingPayment": "*Repeated payment can be easily canceled through your profile settings",
				"terms1a": "I agree with ",
				"terms": "General Terms and Conditions",
				"terms1b": " and with the terms of repeated payments.",
				"youMustAgree": "*You need to agree",
				"membershipCanceled": "Subscription successfuly canceled.",
				"close": "Close",
				"cancelMembership": "Cancel membership",
				"header": "Do you really want to cancel your subscription?",
			},
			footer: {
				"createdBy": "Created by",
			},
		},
	},
	fallbackLng: (store.getState()['lang'])||'cs',

	keySeparator: false, // we use content as keys

	interpolation: {
	escapeValue: false, // not needed for react!!
	formatSeparator: ","

	},

	react: {
		wait: true
	},
});


export default i18n;
