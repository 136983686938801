import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux'
import LoginBusinessForm from './LoginBusinessForm'
import { getCurrentUser } from '../../store/actions/userAction'
import loginAction, { loginReceivedAction } from '../../store/actions/loginAction'
import { Redirect } from 'react-router-dom'
import languageAction from '../../store/actions/languageAction'
import i18n from '../../i18n'

class LoginBusinessContainer extends Component {
  constructor(props){
    super(props);
    this.state={ email:'', password:'', errors:{}, clicked:false }
    this.getRedirect = this.getRedirect.bind(this);
  }

  componentDidMount() {
    if (this.props.match.path === '/login/' &&
        this.props.location.pathname !== '/login/' &&
        this.props.location.pathname !== '/login') {

      let data = this.props.location.pathname.substring(7, this.props.location.pathname.length -1)
      let token = data.substring(0, data.length-3)
      let lang = data.substring(data.length-2, data.length)

      //console.log(token)
      //console.log(lang)

      i18n.translator.language = lang
      i18n.language = lang
      i18n.store.options.fallbackLng[0] = lang
      i18n.options.fallbackLng[0] = lang
      i18n.translator.options.fallbackLng[0] = lang
      this.props.changeLang(lang)

      this.props.loginReceivedAction(token)
      this.props.getCurrentUser()
    }
  }

  getRedirect(){
    if(!this.props.user){
      return null;
    }
    if (this.props.user.isCustomer) {
      return <Redirect to='/customer/'/>
    }
    if (this.props.user.isBusiness) {
      return <Redirect to='/business/'/>
    }
  }

  componentDidUpdate(prevProps){
    if (prevProps.errorMessage!==this.props.errorMessage && this.state.clicked===true) {
      this.setState({errors:this.props.errorMessage});
    }
  }
  componentWillUnmount(){
    this.setState({errors:{}})
  }
  login = () => {
    this.setState({clicked:true})

    this.props.login(this.state.email, this.state.password);
  }

  render() {
    const { errors } = this.state;
    return (
      <Fragment>
        {this.getRedirect()}
        <LoginBusinessForm formData={this.state} onChange={s=>this.setState(s)} error={errors} action={this.login}/>
      </Fragment>
    );
  }
}

function mapStateToProps (state) {
  return {
    user: state.user,
    errorMessage:state.error,
    lang: state.lang,
  }
}

function mapDispatchToProps(dispatch) {
  return {
    login: (email, password) => dispatch(loginAction(email, password)),
    loginReceivedAction: (token) => dispatch(loginReceivedAction(token)),
    getCurrentUser: () => dispatch(getCurrentUser()),
    changeLang: (lang)=>dispatch(languageAction(lang)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(LoginBusinessContainer);
