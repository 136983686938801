import {USER_ID_RECEIVED} from '../actions/userAction';
import humps from 'humps';
import moment from 'moment';

export default (state = {}, action) => {
  switch (action.type) {
    case USER_ID_RECEIVED:
      const user = humps.camelizeKeys(action.user);
      user.birthDate=moment(user.birthDate);
       return user;
    default:
      return state;
  }
}
