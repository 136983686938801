import request from '../../utils/request'
import { getCurrentUser } from './userAction'
import errorAction from './errorAction'

export const LOGIN_RECEIVED = 'LOGIN_RECEIVED'

export const loginReceivedAction = (token) => {
  return {
    type: LOGIN_RECEIVED,
    token: token,
  };
}

export default (email, password) => {
  return (dispatch) => {
    request('/user/auth/', 'POST', { email, password })
    .then(r => dispatch(loginReceivedAction(r.token)))
    .then(() => dispatch(getCurrentUser()))
    .catch(e => e.then(err=>dispatch(errorAction(err))))
    .catch(e => {})
  }
}
