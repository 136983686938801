import React, {Component} from 'react';
import { translate } from 'react-i18next';
import { DeleteIcon, PortraitIcon } from '../../styles/icons/icons';

class Image extends Component {
	render() {
		const { src, handleChange, isProfilePicture, id, changeProfilePicture, handleClickToConfirm, t } = this.props;
		return (
			<div key="image-container" className="image-container">
				<div key="image-inner-container" className="image-inner-container">
					<img src={src} width="100%" height="100%" alt="" style={{backgroundPosition: 'center', backgroundRepeat: 'no-repeat', objectFit: 'cover', objectPosition: 'center'}} />
				</div>
				<div key="image-label" className="image-label">
					<span className="image-label-icon-container" title={t('setAsProfilePicture')} onClick={()=>changeProfilePicture(id)}>
						<PortraitIcon fillColor={ isProfilePicture ? "#E4407C" : "#fff"} onClick={handleChange} />
					</span>
					<span className="image-label-icon-container" title={t('deletePicture')} onClick={e=>handleClickToConfirm(e, "business", id)/*()=>deleteImage(id)*/}><DeleteIcon /></span>
				</div>
			</div>
		);
	}
}

export default translate('image')(Image);
