import {LOG_RECEIVED} from '../actions/logAction';
import humps from 'humps';
import moment from 'moment';

export default (state = {}, action) => {
  switch (action.type) {
    case LOG_RECEIVED:
      const logs = humps.camelizeKeys(action.log);
      for(const actionLog of logs.results){
        actionLog.timestamp = moment(actionLog.timestamp);
      }
      return logs;
    default:
      return state;
  }
}
