import React, { Component } from 'react';
import { connect } from 'react-redux';
import DeleteConfirmationPopup from './DeleteConfirmationPopup';
import { deleteBusinessImage } from '../../store/actions/businessProfileAction';
import { deleteDrinkAction } from '../../store/actions/businessDrinkAction';


class DeleteConfirmationPopupContainer extends Component {
	constructor(props){
	    super(props);
	    this.state={ ifSuccess: null, }
  	}
	deleteBusinessDrink = () => {
	    const actionId =  Math.random();
	    this.props.deleteDrink(this.props.deleteId, actionId);
	    this.setState(prevState => ({
	      ifSuccess: prevState.ifSuccess===null ? actionId : null,
	    }))
  	}
  	deleteBusinessImage = () => {
	    const actionId =  Math.random();
	    this.props.deleteImage(this.props.deleteId, actionId);
	    this.setState(prevState => ({
	      ifSuccess: prevState.ifSuccess===null ? actionId : null,
	    }))
  	}

  	render() {
    	const { isVisible, handleClickToConfirm, deleteOperation, /*deleteId, deleteImage, deleteDrink,*/ success } = this.props;
			const { ifSuccess } = this.state;
			return (
				<DeleteConfirmationPopup
					action={deleteOperation === "drink" ? this.deleteBusinessDrink : this.deleteBusinessImage }
					isVisible={isVisible}
	        ifSuccess={ifSuccess === success}
					handleClickToConfirm={handleClickToConfirm} />
			);
  	}
}

function mapStateToProps(state) {
  return {
    success:state.success,
  }
}
function mapDispatchToProps(dispatch) {
  	return {
      	deleteImage: (id, actionId) => dispatch(deleteBusinessImage(id, actionId)),
      	deleteDrink: (id, actionId) => dispatch(deleteDrinkAction(id, actionId)),
  	}
}

export default connect(mapStateToProps, mapDispatchToProps)(DeleteConfirmationPopupContainer);
