import React, {Component, Fragment} from 'react';
import { translate } from 'react-i18next';
import { EditIcon } from '../../styles/icons/icons';

const days = ['monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday']

class BusinessProfileTable extends Component {
	render() {
		const { user, handleClickToEdit, handleClickToChange, t} = this.props;

		const business = (user && user.business) ? user.business : {};
		const businessId = business.id ? user.business.id : 0;
		const businessInfo = [];
		Object.entries(business).forEach(([key, val]) => {
				//if (val) {
			    if (key !== "id" && !key.startsWith('additionalNote') && key !== "couponHours" && key !== "openingHours" && key !== "houseNo" && key !== "town" && key !== "zipCode" && key !== "street" && key !== "images" && key !== "drinks" && !key.includes("description")) {
			    		businessInfo.push([t(key), val])
			    } else if (key === "openingHours") {
						const openingHours = val.map((day, index) => {
							if (index === 0)
								return <p key={index} style={{marginLeft: 120, marginTop: -20}}>{`${t(days[index])}: ${(day||"").toString().substring(0,5) || t('closed')} - ${(day||"").toString().substring(6,11) || t('closed')}`}</p>
							else
								return <p key={index} style={{marginLeft: 120}}>{`${t(days[index])}: ${(day||"").toString().substring(0,5) || t('closed')} - ${(day||"").toString().substring(6,11) || t('closed')}`}</p>
						})
						businessInfo.push([t('open'),
						 	(<Fragment>{openingHours}</Fragment>)])
			    } else if (key === "street") {
						let zipCode = business.zipCode.toString();
						businessInfo.push([t('address'), `${val} ${business.houseNo} ${business.town} ${zipCode.substring(0,3)} ${zipCode.substring(3,5)}`])
					} else if (key.includes("description")) {
						if (key !== 'description') {
							let name = `description_${key.substring(key.length-2).toLowerCase()}`
							businessInfo.push([t(name), val])
						}
					}
				//}
		});
		const businessLabels = businessInfo.map((el, i) => {
			return (
				<tr key={i}>
			        <td className="table-company-row tal lh1">
		        		<label className="ml10 mt6 lh1_4"><span className="fw400 fs13">{el[0]}&nbsp;</span>{el[1]}</label>
	        		</td>
			    </tr>
			);
		});
		const table = (
			<div>
				<table className="table">
					<tbody>
					    <tr className="upper">
					        <th className="table-company-profile-header-row">
					        	<span className="table-edit-icon icon" title={t('edit')} onClick={e => handleClickToEdit(e, businessId, business)}><EditIcon id="icon" /></span>
					        	{t('companyInfo')}
					        	<label id="changePassword" title={t('changePassword')} className="change-password" onClick={e => handleClickToChange(e)}>{t('changePassword')}</label>
			        		</th>
					    </tr>
					    {businessLabels}
				    </tbody>
			  	</table>
			</div>
		);
		return (
			<div>{table}</div>
		);
	}
}

export default translate('businessProfileTable')(BusinessProfileTable);
