import request from '../../utils/request';
export const LOG_RECEIVED = 'LOG_RECEIVED';

export const logReceivedAction = (log) => {
  return {
    type: LOG_RECEIVED,
    log:log,
  };
}

export default (query = '') => {
  return (dispatch) => {
    request('/log/list/'+query, 'GET')
    .then(r => dispatch(logReceivedAction(r)))
  }
}
