import request from '../../utils/request';
import { getCurrentUser } from './userAction';
import announceSuccess from './successAction';
import errorAction from './errorAction'

export const GET_IMAGE = 'GET_IMAGE';
export const UPLOAD_IMAGE = 'CREATE_SERVICE';

// upload BusinessImage
export const uploadBusinessImage = (r, actionId) => {
  let formData = new FormData();
  formData.append('image', r.selectedImage);
  formData.append('is_title', r.isTitle);
  formData.append('business', r.business);
  return (dispatch) => {
    request('/user/business/image/create/', 'POST', formData, 'multipart/form-data')
    .then(() => dispatch(getCurrentUser()))
    .then(()=>dispatch(announceSuccess(actionId)))
    .catch(e => e.then(err=>dispatch(errorAction(err))))
    .catch(e => {})
  }
}

//delete
export const deleteBusinessImage = (id, actionId) => {
  return (dispatch) => {
    request('/user/business/image/'+id+'/', 'DELETE')
    .then(() => dispatch(getCurrentUser()))
    .then(()=>dispatch(announceSuccess(actionId)))
    .catch(e => {})
  }
}


// change profile
export const changeBusinessProfilePicture = (id, business) => {
  return (dispatch) => {
    request('/user/business/image/'+id+'/', 'PATCH', {is_title: true, business})
    .then(() => dispatch(getCurrentUser()))
    // osetreni ??? Kdyz nastaveno vic
    .catch(e => {})
  }
}

export const updateBusinessProfileAction = (r, weekOpeningHours, actionId) =>{
  let formData = {
    bar_name: r.barName,
    town: r.town,
    street: r.street,
    house_no: r.houseNo,
    zip_code: r.zipCode,
    website: r.website,
    //gps: r.gps,
    contact_name: r.contactName,
    contact_phone: r.contactPhone,
  }
  if (r.ico)
    formData['ico'] = r.ico
  if (r.gps)
    formData['gps'] = r.gps
  if (r.gps)
    formData['contact_email'] = r.contactEmail
  for (let description in r.descriptions) {
    switch(description) {
      case "description_cs":
        formData['description'] = r.descriptions.description_cs
        formData['description_cs'] = r.descriptions.description_cs
        break
      default:
        formData[`description${description.substring(description.indexOf('_'))}`] = r.descriptions[description]
        break
    }
  }
  formData['contact_name'] = r.contactName
  formData['contact_phone'] = r.contactPhone

  let openingHours = [null, null, null, null, null, null, null]
  let hasOpeningHoursChanged = false
  if (weekOpeningHours[0] !== true && weekOpeningHours[0] !== false) {
    hasOpeningHoursChanged = true
    //openingHours[0] = r.mondayOpeningHours
    openingHours[0] = weekOpeningHours[0]
  }
  if (weekOpeningHours[1] !== true && weekOpeningHours[1] !== false) {
    hasOpeningHoursChanged = true
    //openingHours[1] = r.tuesdayOpeningHours
    openingHours[1] = weekOpeningHours[1]
  }
  if (weekOpeningHours[2] !== true && weekOpeningHours[2] !== false) {
      hasOpeningHoursChanged = true
    //openingHours[2] = r.wednesdayOpeningHours
    openingHours[2] = weekOpeningHours[2]
  }
  if (weekOpeningHours[3] !== true && weekOpeningHours[3] !== false) {
      hasOpeningHoursChanged = true
    //openingHours[3] = r.thursdayOpeningHours
    openingHours[3] = weekOpeningHours[3]
  }
  if (weekOpeningHours[4] !== true && weekOpeningHours[4] !== false) {
      hasOpeningHoursChanged = true
    //openingHours[4] = r.fridayOpeningHours
    openingHours[4] = weekOpeningHours[4]
  }
  if (weekOpeningHours[5] !== true && weekOpeningHours[5] !== false) {
      hasOpeningHoursChanged = true
    //openingHours[5] = r.saturdayOpeningHours
    openingHours[5] = weekOpeningHours[5]
  }
  if (weekOpeningHours[6] !== true && weekOpeningHours[6] !== false) {
      hasOpeningHoursChanged = true
    //openingHours[6] = r.sundayOpeningHours
    openingHours[6] = weekOpeningHours[6]
  }
  if (hasOpeningHoursChanged)
    formData['opening_hours'] = openingHours

  // let formData = new FormData();
  // formData.append('bar_name', r.barName);
  // if (r.ico)
  //   formData.append('ico', r.ico);
  // formData.append('town', r.town);
  // formData.append('street', r.street);
  // formData.append('house_no', r.houseNo);
  // formData.append('zip_code', r.zipCode);
  // if (r.gps)
  //   formData.append('gps', r.gps);
  // for (let description in r.descriptions) {
  //   switch(description) {
  //     case "description_cs":
  //       formData.append('description', r.descriptions.description_cs);
  //       formData.append('description_cs', r.descriptions.description_cs);
  //       break
  //     default:
  //       formData.append(`description${description.substring(description.indexOf('_'))}`, r.descriptions[description]);
  //       break
  //   }
  // }
  // formData.append('contact_name', r.contactName);
  // formData.append('contact_phone', r.contactPhone);
  // // if (r.opening)
  // //   formData.append('opening', r.opening);
  // // if (r.closing)
  // //   formData.append('closing', r.closing);
  // let openingHours = [null, null, null, null, null, null, null]
  // let hasOpeningHoursChanged = false
  // if (r.mondayOpeningHours)
  //   openingHours[0] = r.mondayOpeningHours
  // if (r.tuesdayOpeningHours)
  //   openingHours[1] = r.tuesdayOpeningHours
  // if (r.wednesdayOpeningHours)
  //   openingHours[2] = r.wednesdayOpeningHours
  // if (r.thursdayOpeningHours)
  //   openingHours[3] = r.thursdayOpeningHours
  // if (r.fridayOpeningHours)
  //   openingHours[4] = r.fridayOpeningHours
  // if (r.saturdayOpeningHours)
  //   openingHours[5] = r.saturdayOpeningHours
  // if (r.sundayOpeningHours)
  //   openingHours[6] = r.sundayOpeningHours
  // if (hasOpeningHoursChanged)
  //   formData('opening_hours', openingHours);

  return (dispatch) => {
    request('/user/business/'+r.businessId+'/', 'PATCH', formData)
    .then(() => dispatch(getCurrentUser()))
    .then(()=>dispatch(announceSuccess(actionId)))
    .catch(e => e.then(err=>dispatch(errorAction(err))))
    .catch(e => {})
  }
}


export default {
  uploadBusinessImage,
  deleteBusinessImage,
  changeBusinessProfilePicture,
  updateBusinessProfileAction
}
