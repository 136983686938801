import React, {Component, Fragment} from 'react';
import { translate } from 'react-i18next';
/*import { EditIcon } from 'styles/icons/icons';*/

class DrinkOverview extends Component {
	render() {
		const { drink, t } = this.props;
		return (
			<Fragment>
				{ (drink && drink.image && drink.price ) &&
					(
						<div className="drink-overview-container">
							<div className="flex">
								<div className="drink-details-container">
									<h3 className="drink-name">{drink.name || ""}</h3>
									{ drink.nameCs && <Fragment><label className="drink-details-text mt4b2"><span className="fw500 fs12">{t('nameCs')}&nbsp;</span> {`${drink.nameCs || ""}`}</label><br /></Fragment> }
									{ drink.nameEn && <Fragment><label className="drink-details-text mt4b2"><span className="fw500 fs12">{t('nameEn')}&nbsp;</span> {`${drink.nameEn || ""}`}</label><br /></Fragment> }
									{ drink.description   && <Fragment><label className="drink-details-text mt4b2"><span className="fw500 fs12">{t('composition')}&nbsp;</span> {`${drink.description || ""}`}</label><br /></Fragment> }
									{ drink.descriptionCs && <Fragment><label className="drink-details-text mt4b2"><span className="fw500 fs12">{t('compositionCs')}&nbsp;</span> {`${drink.descriptionCs || ""}`}</label><br /></Fragment> }
									{ drink.descriptionEn && <Fragment><label className="drink-details-text mt4b2"><span className="fw500 fs12">{t('compositionEn')}&nbsp;</span> {`${drink.descriptionEn || ""}`}</label><br /></Fragment> }
									<label className="drink-details-text mt4b2"><span className="fw500 fs12">{t('price')}&nbsp;</span>{`${drink.price || ""} ${t('currency')}`}</label><br />
									<label className="drink-details-text mt4b2"><span className="fw500 fs12">{t('approved')}&nbsp;</span>{`${ drink.approved ? "Ano" : "Ne"}`}</label>
								</div>
								<div className="drink-image-container">
									<img src={drink.image} width="100%" height="100%" alt="" style={{backgroundPosition: 'center', backgroundRepeat: 'no-repeat', objectFit: 'cover', objectPosition: 'center'}} />
								</div>
							</div>
						</div>
					)
				}
			</Fragment>
		);
	}
}

export default translate('drinkOverview')(DrinkOverview);
