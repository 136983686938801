import React, { Component, Fragment } from 'react';
import { translate } from 'react-i18next';
import Button from '../common/button/Button';
import Form from '../common/form/Form';

class EditBusinessProfilePop extends Component {
	constructor(props) {
		super(props)
		const schema = this.updateSchema(false)
		this.state = {
			schema: schema,
			ifChange: false,
			openingHoursError: '',
		}
		this.updateSchema = this.updateSchema.bind(this);
	}
	componentDidUpdate(prevProps){
		if (prevProps.error!==this.props.error) {
			this.setState({ifChange: true});
		}
	}
	updateSchema(shouldUpdateState = true) {
		const { formData, error, t } = this.props;
		const schema = []
		for (let data in formData) {
			let name = t(data)
			switch(data) {
				case "barName":
					schema.push({name:name, path:data, type:'barName', errorMsg:error.barName?error.barName:null, isInvalid:error.barName?'errorClass':''})
					break
				case "ico":
					schema.push({name:name, path:data, type:'ico', errorMsg:error.ico?error.ico:null, isInvalid:error.ico?'errorClass':''})
					break
				case "town":
					schema.push({name:name, path:data, errorMsg:error.town?error.town:null, isInvalid:error.town?'errorClass':''})
					break
				case "street":
					schema.push({name:name, path:data, errorMsg:error.street?error.street:null, isInvalid:error.street?'errorClass':''})
					break
				case "houseNo":
					schema.push({name:name, path:data, type:'houseNo', errorMsg:error.houseNo?error.houseNo:null, isInvalid:error.houseNo?'errorClass':''})
					break
				case "zipCode":
					schema.push({name:name, path:data, type:'zipCode', errorMsg:error.zipCode?error.zipCode:null, isInvalid:error.zipCode?'errorClass':''})
					break;
				case "gps":
					schema.push({name:name, path:data, errorMsg:error.gps?error.gps:null, isInvalid:error.gps?'errorClass':''})
					break
				case "website":
					schema.push({name:name, path:data, errorMsg:error.website?error.website:null, isInvalid:error.website?'errorClass':''})
					break
				case "contactName":
					schema.push({name:name, path:data, errorMsg:error.contactName?error.contactName:null, isInvalid:error.contactName?'errorClass':''})
					break
				case "contactEmail":
					schema.push({name:name, path:data, errorMsg:error.contactEmail?error.contactEmail:null, isInvalid:error.contactEmail?'errorClass':''})
					break
				case "contactPhone":
					schema.push({name:name, path:data, type:'tel', errorMsg:error.contactPhone?error.contactPhone:null, isInvalid:error.contactPhone?'errorClass':''})
					break
				case "mondayOpeningHours":
					schema.push({name:name, path:data, type:'openingHours', errorMsg:(error.openingHours||[])[0]?(error.openingHours||[])[0]:null, isInvalid:(error.openingHours||[])[0]?'errorClass':''})
					break
				case "tuesdayOpeningHours":
					schema.push({name:name, path:data, type:'openingHours', errorMsg:(error.openingHours||[])[1]?(error.openingHours||[])[1]:null, isInvalid:(error.openingHours||[])[1]?'errorClass':''})
					break
				case "wednesdayOpeningHours":
					schema.push({name:name, path:data, type:'openingHours', errorMsg:(error.openingHours||[])[2]?(error.openingHours||[])[2]:null, isInvalid:(error.openingHours||[])[2]?'errorClass':''})
					break
				case "thursdayOpeningHours":
					schema.push({name:name, path:data, type:'openingHours', errorMsg:(error.openingHours||[])[3]?(error.openingHours||[])[3]:null, isInvalid:(error.openingHours||[])[3]?'errorClass':''})
					break
				case "fridayOpeningHours":
					schema.push({name:name, path:data, type:'openingHours', errorMsg:(error.openingHours||[])[4]?(error.openingHours||[])[4]:null, isInvalid:(error.openingHours||[])[4]?'errorClass':''})
					break
				case "saturdayOpeningHours":
					schema.push({name:name, path:data, type:'openingHours', errorMsg:(error.openingHours||[])[5]?(error.openingHours||[])[5]:null, isInvalid:(error.openingHours||[])[5]?'errorClass':''})
					break
				case "sundayOpeningHours":
					schema.push({name:name, path:data, type:'openingHours', errorMsg:(error.openingHours||[])[6]?(error.openingHours||[])[6]:null, isInvalid:(error.openingHours||[])[6]?'errorClass':''})
					break
				case "descriptions":
					break
				default:
					break
			}
		}
		for (let description in formData.descriptions) {
			let path = `descriptions.${description}`
			if (description === 'description_cs') {
				schema.push({name:t(description), path:path, errorMsg:error.description?error.description:null, isInvalid:error.description?'errorClass':'' })
			} else {
				schema.push({name:t(description), path:path, })
			}
		}
		if (shouldUpdateState)
			this.setState({schema:schema, ifChange: false})
		else
			return schema
	}
	wrongOpeningHours = () => {
		const { t } = this.props
		this.setState({openingHoursError: t('openingHoursError')})
		return false
	}
	checkOpeningHours = (openingHours, day) => {
		let string = openingHours
		if (!openingHours || openingHours === null)
			return true
		if (!openingHours.includes(':') || !openingHours.includes('-'))
			return this.wrongOpeningHours()
		if (openingHours.indexOf(':') === 1) {
			if ('0' <= openingHours.charAt(0) <= '9')
				string = '0' + openingHours
			else
				return this.wrongOpeningHours()
		} else if (openingHours.indexOf(':') === 2) {
			if (!('0' <= openingHours.charAt(0) <= '2') || !('0' <= openingHours.charAt(1) <= '9'))
				return this.wrongOpeningHours()
		} else {
			return this.wrongOpeningHours()
		}

		if (string.indexOf('-') === 5) {
			if (string.indexOf(':', '3') === 7) {
				if ('0' <= openingHours.charAt(0) <= '9')
					string = string.substring(0, 6) + '0' + string.substring(6, 10)
				else
					return this.wrongOpeningHours()
			} else if (string.indexOf(':', '3') === 8) {
				if (!('0' <= openingHours.charAt(6) <= '2') || !('0' <= openingHours.charAt(7) <= '9'))
					return this.wrongOpeningHours()
			}
		} else {
			return this.wrongOpeningHours()
		}

		if (string.length !== 11)
			return this.wrongOpeningHours()

		let oH = parseInt(string.substring(0, 2), 10)
		let cH = parseInt(string.substring(6, 8), 10)
		let oM = parseInt(string.substring(3, 5), 10)
		let cM = parseInt(string.substring(9, 11), 10)
		if ((0 <= oH && oH <= 24) && (0 <= cH && cH <= 24)) {
			if ((0 <= oM && oM <= 59) && (0 <= cM && cM <= 59)) {
				return string
			} else {
				return this.wrongOpeningHours()
			}
		} else {
			return this.wrongOpeningHours()
		}
	}
	checkAllOpeningHours = () => {
		const { formData } = this.props
		let week = []
		week.push(this.checkOpeningHours(formData.mondayOpeningHours))
		week.push(this.checkOpeningHours(formData.tuesdayOpeningHours))
		week.push(this.checkOpeningHours(formData.wednesdayOpeningHours))
		week.push(this.checkOpeningHours(formData.thursdayOpeningHours))
		week.push(this.checkOpeningHours(formData.fridayOpeningHours))
		week.push(this.checkOpeningHours(formData.saturdayOpeningHours))
		week.push(this.checkOpeningHours(formData.sundayOpeningHours))
		if (week.includes(false))
			return false
		return week
	}
	render() {
		const { formData, onChange, action, error, isVisible, handleClickToEdit, disabled, ifSuccess, /*business, businessId,*/ t} = this.props ;
		if (this.state.ifChange) {
			this.updateSchema()
		}
		const errLabel = (error||[])[0] ? <label style={{color:"red"}}>{(error||[])[0]}</label> : <label style={{color:"red"}}>{this.state.openingHoursError}</label>;
		return (
			<Fragment>
				{
					isVisible &&
					(
						<div className="outer-edit-profile-container" onClick={(ifSuccess === true) ? handleClickToEdit({}, null, "", true) : e => handleClickToEdit(e, null, "")}>
							<div className="edit-profile-form-container">
								<div className="edit-form">
									<span className="closeIcon" aria-hidden="true" onClick={e => handleClickToEdit(e, null, "")}>×</span>
									<p className="tal popup-header" key="header">{t('header')}</p>
									<Form
					            formData={formData}
					            onChange={onChange}
					            visibleHints={true}
											backgroundDark={true}
                    	schema={this.state.schema}/>
									{errLabel}
									<div className="flex options-container">
										<label className="tal flex-grow-help">{t('help')}</label>
										<Button title={t('save')} disabled={disabled} onClick={()=>{let data = this.checkAllOpeningHours();if(data !== false){action(data)}}} style={disabled ? {opacity: 0.5, backgroundColor: "#FE2183", fontSize: 14} : {backgroundColor: "#FE2183", fontSize: 14}}>{t('save')}</Button>
									</div>
								</div>
							</div>
						</div>
					)
				}
			</Fragment>
		);
	}
}

export default translate('editBusinessProfilePop')(EditBusinessProfilePop);
