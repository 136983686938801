import React, { Component } from 'react';

import BusinessProfileTableContainer from '../businessProfileTable/BusinessProfileTableContainer';
import ImagesOverviewContainer from '../imagesOverview/ImagesOverviewContainer';


class BusinessProfile extends Component {
	render() {
		const {handleChangeOfDrinkName, handleClickToEdit, handleClickToUpload, handleClickToChange, handleClickToConfirm } = this.props;
		return (
			<div style={{margin: "0 20px"}}>
				<BusinessProfileTableContainer handleClickToEdit={handleClickToEdit} handleClickToChange={handleClickToChange} />
				<ImagesOverviewContainer handleChangeOfDrinkName={handleChangeOfDrinkName} handleClickToUpload={handleClickToUpload} handleClickToConfirm={handleClickToConfirm} />
			</div>
		);
	}
}

export default BusinessProfile;
