import React, {Component} from 'react';
import { translate } from 'react-i18next';
import Button from '../common/button/Button';
import { EditIcon, DeleteIcon, VerifiedIcon, UnverifiedIcon } from '../../styles/icons/icons';


class BusinessDrinksTable extends Component {
	render() {
		const { user, handleClickToEdit, handleClickToShow, handleClickToConfirm, t} = this.props;

		const isUserDefined = user ? true : false;
		const drinks = (isUserDefined && (user.business||{}).drinks && (user.business||{}).drinks !== []) ? user.business.drinks : [];
		const businessId = (isUserDefined && (user.business||{}).id) ? (user.business||{}).id : 0;

		let drinkRows;
		if (drinks.length > 0) {
			drinkRows = drinks.map(drink => {
				return (
					<tr key={drink.id} onClick={(e) => handleClickToShow(e, drink)}>
						<td className="table-company-row">
	        		<span className="table-edit-icon icon" title={t('edit')} onClick={e => {handleClickToEdit(e, businessId, drink);handleClickToShow({}, {}, true)}}><EditIcon /></span>
	        		<label className="table-drink-name">{drink.name}</label>
	        	</td>
		        <td className="lh2 table-company-row">
	        		{drink.description||""}
	        	</td>
	        	<td className="lh2 table-company-row">
	        		{`${drink.price} ${t('currency')}`}
	        	</td>
		        <td className="lh2 table-company-row">
		        	{ drink.approved ? <VerifiedIcon fillColor="#00b300" /> : <UnverifiedIcon fillColor="#cc0000" /> }
	        	</td>
		        <td className="lh2 table-company-row">
		        	<span className="icon" title={t('delete')} onClick={(e) => {handleClickToConfirm(e, "drink", drink.id)/*action(drink.id)*/;handleClickToShow({}, {}, true)}}>
		        		<DeleteIcon />
	        		</span>
	        	</td>
					</tr>
				)
			})
		}

		const table = (
			<div>
		 		<table className="table">
		 			<tbody>
					    <tr className="upper">
					        <th className="table-company-drinks-header-row-left-end c-gray">
					    		<span style={{marginRight: 8}}>{t('drinksOverview')}</span>
					    		{ (drinks.length < 3) && <Button id="addDrink" title={t('addDrink')} onClick={e => handleClickToEdit(e, businessId, "")} style={{height: 30, fontSize: 9, fontWeight: 300, lineHeight: 0}}>{t('addDrink')}</Button> }
				    		</th>
					        <th className="lh2 table-company-drinks-header-row c-gray">{t('drinksComposition')}</th>
					        <th className="lh2 table-company-drinks-header-row c-gray">{t('drinksPrice')}</th>
					        <th className="lh2 table-company-drinks-header-row c-gray">{t('approved')}</th>
					        <th className="lh2 table-company-drinks-header-row-right-end c-gray">{t('sale')}</th>
				    	</tr>
				    	{drinkRows}
				    </tbody>
			  	</table>
			</div>
		);
		return (
			<div>{table}</div>
		);
	}
}

export default translate('businessDrinksTable')(BusinessDrinksTable);
