import { createStore, applyMiddleware, combineReducers } from 'redux';
//import { composeWithDevTools } from 'redux-devtools-extension';
//import { composeWithDevTools } from 'redux-devtools-extension/logOnly';
import thunk from 'redux-thunk';
import userReducer from './reducers/userReducer';
import loginReducer from './reducers/loginReducer';
import businessListReducer from './reducers/businessListReducer';
import userListReducer from './reducers/userListReducer';
import logoutReducer from './reducers/logoutReducer';
import logReducer from './reducers/logReducer';
import userIdReducer from './reducers/userIdReducer';
import successReducer from './reducers/successReducer';
import errorReducer from './reducers/errorReducer';
import repeatingPasswordErrorReducer from './reducers/repeatingPasswordErrorReducer';
import languageReducer from './reducers/languageReducer';
import getPaymentInfoReducer from './reducers/getPaymentInfoReducer'
import makePaymentReducer from './reducers/makePaymentReducer'
import getMembershipInfoReducer from './reducers/getMembershipInfoReducer'
import { persistStore, persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage' // defaults to localStorage for web and AsyncStorage for react-native


const stackReducers = (...reducers) => {
  return (state=[], action) => {
    let newState = state;
    for(const reducer of reducers){
      newState = reducer(newState, action);
    }
    return newState;
  }
}

const persistConfig = {
  key: 'root',
  storage,
}


export const initialState = {
  token: '',
  user: null,
  businessList: [],
  adminInfo: {
    userList:[],
    log:{}
  },
  lang: 'cs',
  success:'',
  error:[],
  repeatingPasswordError:null,
  makePaymentResponse: {
    amount: 0,
    gwUrl: '',
  },
  membership: {
    membershipPrice: 0,
  },
  payment: {},
};

export const store = createStore(
  persistReducer(persistConfig, stackReducers(
    combineReducers({
      token: loginReducer,
      user: userReducer,
      businessList: businessListReducer,
      adminInfo: combineReducers({
        userList: userListReducer,
        log: logReducer,
        userID:userIdReducer
      }),
      lang: languageReducer,
      success: successReducer,
      error:errorReducer,
      repeatingPasswordError:repeatingPasswordErrorReducer,
      makePaymentResponse: makePaymentReducer,
      membership: getMembershipInfoReducer,
      payment: getPaymentInfoReducer,
    }),
    logoutReducer)),
  initialState, applyMiddleware(thunk));
  //,composeWithDevTools(applyMiddleware(thunk)));

/*
composeWithDevTools(
  applyMiddleware(thunk)
)

applyMiddleware(thunk)
*/
/*composeWithDevTools({
features: {
    pause: false, // start/pause recording of dispatched actions
    lock: false, // lock/unlock dispatching actions and side effects
    persist: false, // persist states on page reloading
    export: false, // export history of actions in a file
    import: 'custom', // import history of actions from a file
    jump: false, // jump back and forth (time travelling)
    skip: false, // skip (cancel) actions
    reorder: false, // drag and drop actions in the history list
    dispatch: false, // dispatch custom actions or action creators
    test: false // generate tests for the selected actions
  },
  applyMiddleware(thunk)
});*/
export const persistor = persistStore(store);
