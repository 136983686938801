import { MEMBERSHIP_INFO_RECEIVED } from '../actions/getMembershipInfoAction';
import humps from 'humps';

export default (state = [], action) => {
  	switch (action.type) {
    	case MEMBERSHIP_INFO_RECEIVED:
      		const newMembershipInfo = humps.camelizeKeys(action.membershipInfo)
      		return newMembershipInfo;
    	default:
    		return state;
  	}
}
