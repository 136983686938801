import React, { Component } from 'react';
import { connect } from 'react-redux';
import ImagesOverview from './ImagesOverview';

import { changeBusinessProfilePicture } from '../../store/actions/businessProfileAction';

class ImagesOverviewContainer extends Component {
	render() {
		const {user, type, handleChangeOfDrinkName, handleClickToUpload, /*deleteImage,*/ changeProfilePicture, handleClickToConfirm} = this.props;
		return (
			<ImagesOverview
				user={user}
				type={type}
				handleChangeOfDrinkName={handleChangeOfDrinkName}
				handleClickToUpload={handleClickToUpload}
				changeProfilePicture={(id)=>changeProfilePicture(id)}
				handleClickToConfirm={handleClickToConfirm} />
		);
	}
}

function mapStateToProps(state) {
	return {
		user: state.user,
	}
}

function mapDispatchToProps(dispatch){
	return {
		changeProfilePicture:(id)=>dispatch(changeBusinessProfilePicture(id)),
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(ImagesOverviewContainer);
