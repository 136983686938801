import request from '../../utils/request';
import announceSuccess from './successAction';
import errorAction from './errorAction';
//import { repeatingPasswordError } from './repeatingPasswordErrorAction';

export const REGISTRATION_RECEIVED = 'REGISTRATION_RECEIVED';

export const registerReceivedAction = () => {
  return {
    type: REGISTRATION_RECEIVED,
  };
}


export default (r, actionId) => {
  let formData = {
    email: r.email,
    business: {
      bar_name: r.barName,
      //ico: r.ico,
      town: r.town,
      street: r.street,
      house_no: r.houseNo,
      zip_code: r.zipCode,
      //gps: r.gps,
      contact_name: r.contactName,
      contact_phone: r.contactPhone,
      //opening: r.opening,
      //closing: r.closing,
    },
    //password: r.password,
  }
  if (r.ico)
    formData['business']['ico'] = r.ico
  if (r.gps)
    formData['business']['gps'] = r.gps
  if (r.gps)
    formData['business']['contact_email'] = r.contactEmail
  // if (r.opening)
  //   formData['business']['opening'] = r.opening
  // if (r.closing)
  //   formData['business']['closing'] = r.closing
  for (let description in r.descriptions) {
    switch(description) {
      case "description_cs":
        formData['business']['description'] = r.descriptions.description_cs
        formData['business']['description_cs'] = r.descriptions.description_cs
        break
      default:
        formData['business'][description] = r.descriptions[description]
        break
    }
  }
  return (dispatch) => {
    request('/user/business/register/', 'POST', formData)
    .then(r => dispatch(registerReceivedAction()))
    .then(()=>dispatch(announceSuccess(actionId)))
    .catch(e => e.then(err=>dispatch(errorAction(err))))
    .catch(e => {})
  }
}
