import React, {Component, Fragment} from 'react';
import { translate } from 'react-i18next';
import { Link } from 'react-router-dom';
import Logo from '../../styles/icons/logo.svg';
import Button from '../common/button/Button';
import i18n, { languages } from '../../i18n';

const btnStyle = {margin: "2px 4px", fontWeight: 300, border: "1px solid #fff",  background: "transparent", height: 38, fontSize: 12, color: "#FFF", textTransform: "initial"};

class TopBar extends Component {
    constructor(props) {
      super(props);
      let lang = this.props.lang ? this.props.lang: languages[0]
      //let lang = languages.includes(i18n.language) ? i18n.language : languages[0]
      let indexLang = languages.indexOf(lang)
      i18n.translator.language = lang
      i18n.language = lang
      i18n.store.options.fallbackLng[0] = lang
      i18n.options.fallbackLng[0] = lang
      i18n.translator.options.fallbackLng[0] = lang
      this.state = { shouldRenderMobileMenu: false, indexLang: indexLang, lang: lang };
      this.handleClickToShow = this.handleClickToShow.bind(this);
      this.handleClickToDisappear = this.handleClickToDisappear.bind(this);
    }
    handleClickToShow() {
      this.setState({shouldRenderMobileMenu: true})
    }
    handleClickToDisappear() {
      this.setState({shouldRenderMobileMenu: false})
    }
    changeLanguage = () => {
      let indexLang = (this.state.indexLang + 1) >= languages.length ? 0 : this.state.indexLang + 1;
      let nextLang = languages[indexLang]
      window.location.reload()
      this.setState({indexLang: indexLang, lang: nextLang})
      i18n.translator.language = nextLang
      i18n.language = nextLang
      i18n.store.options.fallbackLng[0] = nextLang
      i18n.options.fallbackLng[0] = nextLang
      i18n.translator.options.fallbackLng[0] = nextLang
      this.props.changeLang(nextLang)
    }
    render() {
        const { user, logout, topbarStyle, t } = this.props;
        const { shouldRenderMobileMenu/*, lang*/} = this.state;
        const isUserOnBusinessPage = window.location.href.toString().includes("/business")
        const isUserOnCustomerPage = window.location.href.toString().includes("/customer")
        return (
            <nav id="topbar" className="topbar">
              <div className="nav-bar" style={topbarStyle==="customer" ? {minWidth: 0} : {}}>
                <Link className="app-name upper" to="/">
                  {/*t('appName')*/}
                  <img src={Logo} width="170px" height="58px" alt="logo" />
                </Link>
                <div id="responsive-menu">
                  { user ?
                    <div style={{lineHeight: 4.5}}>
                      { (!isUserOnBusinessPage && user.isBusiness) && <label style={{marginRight: 20}}><Link to="/business" title={t('profile')} className="options-link tac">{t('profile')}</Link></label> }
                      { (!isUserOnCustomerPage && user.isCustomer) && <label style={{marginRight: 20}}><Link to="/customer" title={t('profile')} className="options-link tac">{t('profile')}</Link></label> }
                      <Link onClick={()=>{logout()}} to="/" title={t('logout')} className="options-link tac">{t('logout')}</Link>
                    </div>
                    :
                    <div className="flex">
                      <ul id="catalog-big-menu" className="nav">
                        <li className="catalog-nav-bar-item"><a className="nav-bar-link" href="https://freelyapp.cz">{t('howItWorks')}</a></li>
                        <li className="catalog-nav-bar-item"><a className="nav-bar-link" href="https://freelyapp.cz/zapoj-svuj-bar/">{t('connectYourBar')}</a></li>
                        <li className="catalog-nav-bar-item"><Link className="nav-bar-link nav-bar-link-active" to="/">{t('catalog')}</Link></li>
                        <li className="catalog-nav-bar-item"><a className="nav-bar-link" href="https://freelyapp.cz/blog/">{t('blog')}</a></li>
                        <li className="catalog-nav-bar-item"><a className="nav-bar-link" href="https://freelyapp.cz/kontakt/">{t('contact')}</a></li>
                        <Link to="/login" style={{marginTop: 4}}><Button title={t('login')} style={btnStyle}>{t('login')}</Button></Link>
                        <li style={{marginTop: 4}}><Button title={t('changeLanguage')} style={btnStyle} onClick={() => this.changeLanguage()}>{languages[(this.state.indexLang + 1) >= languages.length ? 0 : this.state.indexLang + 1].toUpperCase()}</Button></li>
                      </ul>
                    </div>
                    }
                </div>
                { (!user && !shouldRenderMobileMenu) && <span id="mobile-menu-hamburger" onClick={() => this.handleClickToShow()}><svg fill="#fff" height="32" width="32" xmlns="http://www.w3.org/2000/svg"><path d="M4 10h24a2 2 0 0 0 0-4H4a2 2 0 0 0 0 4zm24 4H4a2 2 0 0 0 0 4h24a2 2 0 0 0 0-4zm0 8H4a2 2 0 0 0 0 4h24a2 2 0 0 0 0-4z"/></svg></span> }
                { shouldRenderMobileMenu &&
                  <Fragment>
                    <span id="mobile-menu-close" style={topbarStyle==="business" ? {} : {minWidth: 0}} onClick={() => this.handleClickToDisappear()}><svg fill="#fff" height="32" viewBox="0 0 512 512" width="32" xmlns="http://www.w3.org/2000/svg"><path d="M437.5 386.6L306.9 256l130.6-130.6c14.1-14.1 14.1-36.8 0-50.9-14.1-14.1-36.8-14.1-50.9 0L256 205.1 125.4 74.5c-14.1-14.1-36.8-14.1-50.9 0-14.1 14.1-14.1 36.8 0 50.9L205.1 256 74.5 386.6c-14.1 14.1-14.1 36.8 0 50.9 14.1 14.1 36.8 14.1 50.9 0L256 306.9l130.6 130.6c14.1 14.1 36.8 14.1 50.9 0 14-14.1 14-36.9 0-50.9z"/></svg></span>
                    <div id="mobile-menu">
                      <ul>
                        <li><a className="responsive-menu-item" href="https://freelyapp.cz">{t('howItWorks')}</a></li>
                        <li><a className="responsive-menu-item" href="https://freelyapp.cz/zapoj-svuj-bar/">{t('connectYourBar')}</a></li>
                        <li><Link className="responsive-menu-item" to="/">{t('catalog')}</Link></li>
                        <li><a className="responsive-menu-item" href="https://freelyapp.cz/blog/">{t('blog')}</a></li>
                        <li><a className="responsive-menu-item" href="https://freelyapp.cz/kontakt/">{t('contact')}</a></li>
                        <li><Link className="responsive-menu-item" to="/login"><div title={t('login')} style={{fontWeight: 300, border: "1px solid rgb(255, 255, 255)", background: "transparent", fontSize: 12, color: "#fff", padding: 10, borderRadius: 10, margin: -10}}>{t('login')}</div></Link></li>
                        <li className="responsive-menu-item"><div onClick={() => this.changeLanguage()} title={t('changeLanguage')} style={{fontWeight: 300, border: "1px solid rgb(255, 255, 255)", background: "transparent", fontSize: 12, color: "#fff", padding: 10, borderRadius: 10, margin: -10}}>{languages[(this.state.indexLang + 1) >= languages.length ? 0 : this.state.indexLang + 1].toUpperCase()}</div></li>
                      </ul>
                    </div>
                  </Fragment>
                }
              </div>
            </nav>
        );
    }
}

export default translate('topBar')(TopBar);
