import React, {Component} from 'react';
import DrinkLabel from '../drinkLabel/DrinkLabel';

class BusinessCard extends Component {
	render() {
		const { business, name , description, address, drinks, background, handleClickToShow } = this.props;
		const drinkLabels = drinks.map((drink) => {
			return <DrinkLabel key={drink.id} image={drink.image} name={drink.name} description={drink.description||""} price={drink.price} drink={drink} />
		});
		return (
			<div key="container" className="card-business" onClick={() => handleClickToShow(business)}>
				<img src={background} width="100%" height="100%" style={{borderRadius: 20, opacity: 0.6, objectFit: 'cover', objectPosition: 'center'}} alt="" />
			  	<div key="business-card-container" className="business-card-container bg-blur">
				    <label key="business-name" className="business-card-name upper m0">{name}</label>
				    <p key="business-description" className="business-card-description c-pink m0">{description}</p>
				    <p key="business-address" className="business-card-address m0">{address}</p>
				    <div key="drinks-container" className="drinks-container">
				    	{drinkLabels}
				    </div>
			  	</div>
			</div>
		);
	}
}

export default BusinessCard;
