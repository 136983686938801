import { LANG_RECEIVED } from '../actions/languageAction';

export default (state = '', action) => {
  switch (action.type) {
    case LANG_RECEIVED:
      return action.lang
    default:
      return state
  }
}
