import request from '../../utils/request';
import errorAction from './errorAction'
export const GET_PAYMENT_INFO = 'GET_PAYMENT_INFO';

export const getPaymentInfo = (paymentResponse) => {
  return {
    type: GET_PAYMENT_INFO,
    paymentResponse: paymentResponse,
  };
}

export default (id) => {
  return (dispatch) => {
    request(`/gopay/payment/${id}/`, 'get')
    .then(r => dispatch(getPaymentInfo(r)))
    .catch(e => e.then(err=>dispatch(errorAction(err))))
    .catch(e=>{})
  }
}
