import { store } from '../store/store';
import API from './apiAddress';

export default (endpoint, method = 'GET', body = undefined, contentType = "application/json") => {
  try {
    const token = store.getState().token;

    let bodyIn = body
    let headersIn = {};

    if (contentType === "application/json"){
        bodyIn = JSON.stringify(body)
        headersIn["Content-Type"] = "application/json"
    }

    headersIn["accept-language"] = store.getState()['lang'] || 'cs'

    if (token)
      headersIn["Authorization"] = `JWT ${token}`
    else
      headersIn["Authorization"] = null

    return fetch(
      `https://${API}${endpoint}`,
      {
        method: method,
        body: bodyIn,
        headers: headersIn,
      })
      .then(response => {
        try {
          if(response.ok){
            return response;
          }
          if ((response.status+'').startsWith('4') || (response.status+'').startsWith('5')) {
            return Promise.reject(response.json())
          }
          throw response.statusText;
        } catch(error) {
          // TODO
        }
      }, e => {/*console.log(e)*/})
      .then(response => {
        try {
          if (response.status === 204) {
            return {};
          }
          return response.json()
        } catch(error) {
          // TODO
        }
      });
    } catch(error) {

    }
}
