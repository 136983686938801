import { GET_PAYMENT_INFO } from '../actions/getPaymentInfoAction';
import { RESET_PAYMENT } from '../actions/resetPaymentAction'
import humps from 'humps';

export default (state = [], action) => {
  	switch (action.type) {
    	case GET_PAYMENT_INFO:
      		const newPaymentResponse = humps.camelizeKeys(action.paymentResponse)
      		return newPaymentResponse
      case RESET_PAYMENT:
          return {}
    	default:
    		return state
  	}
}
