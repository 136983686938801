import React, {Component, Fragment} from 'react';
import RegistrationBusinessFormContainer from '../../../components/registrationBusinessForm/RegistrationBusinessFormContainer';
import { languages } from '../../../i18n';
//import Footer from '../../../components/footer/Footer'

class RegistrationPage extends Component {
	render() {
		const langs = []
		for (let lang of languages) {
			langs.push(`description_${lang}`);
		}
		return (
			<Fragment>
				<RegistrationBusinessFormContainer languages={langs} />
				{/*<div style={{marginTop: -60}}><Footer /></div>*/}
			</Fragment>
		);
	}
}

export default RegistrationPage;
