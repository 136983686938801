import React, { Component } from 'react';
import { BrowserRouter, Route, Switch } from "react-router-dom";
import LoginPage from './common/login/LoginPage';
import RegistrationPage from './common/registration/RegistrationPage';
import ForgottenPasswordPage from './common/forgottenPassword/ForgottenPasswordPage';
import CustomerIndex from './customer/CustomerIndex';
import BusinessIndex from './business/BusinessIndex';
import CatalogPage from './catalog/CatalogPage';
import AfterPaymentPage from './afterPaymentPage/AfterPaymentPage'
import ProtectedRoute from '../components/common/protectedRoute/ProtectedRoute';

class Router extends Component {
  render() {
    return (
      <BrowserRouter>
        <Switch>
          <ProtectedRoute path="/customer/" component={CustomerIndex} />
          <ProtectedRoute path="/business/" component={BusinessIndex} />
          <Route path="/login/" component={LoginPage} />
          <Route path="/register/" component={RegistrationPage} />
          <Route path="/forgottenPassword/" component={ForgottenPasswordPage} />
          <Route path="/acknowledgment/" component={AfterPaymentPage} />
          <Route path="/" component={CatalogPage} />
        </Switch>
      </BrowserRouter>
    );
  }
}

export default Router;
