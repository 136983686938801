import request from '../../utils/request'
import { getCurrentUser } from './userAction'
export const MAKE_PAYMENT_RECEIVED = 'MAKE_PAYMENT_RECEIVED';

export const makePaymentReceived = (paymentResponse) => {
  return {
    type: MAKE_PAYMENT_RECEIVED,
    paymentResponse: paymentResponse,
  };
}

export default () => {
  return (dispatch) => {
    request('/gopay/make-payment/', 'POST', {})
    .then(r => dispatch(makePaymentReceived(r)))
    .then(() => dispatch(getCurrentUser()))
    .catch(e => e.then(err=>alert(err)))
    .catch(e=>{})
  }
}
