import React, { Component, Fragment } from 'react';
import { translate } from 'react-i18next';
import Button from '../common/button/Button';


class DeleteConfirmationPopup extends Component {
	render() {
		const { action, isVisible, handleClickToConfirm, ifSuccess, t} = this.props;
		return (
			<Fragment>
				{
					isVisible &&
					(
						<div className="outer-delete-container" onClick={(ifSuccess === true) ? handleClickToConfirm({}, null, null, true) : e => handleClickToConfirm(e, null, null)} >
							<div className="delete-form-container">
								<div className="edit-form">
									<span className="closeIcon" aria-hidden="true" onClick={e => handleClickToConfirm(e, null, null)}>×</span>
									<p className="tal popup-header" key="header">{t('header')}</p>

									<div className="flex upload-container">
										<Button title={t('delete')} onClick={() => action()} style={{marginRight: 10, border: "1px solid #fff",  background: "transparent", fontSize: 14}}>{t('delete')}</Button>
										<Button id="cancel-delete" title={t('cancel')} onClick={e => handleClickToConfirm(e, null, null)}>{t('cancel')}</Button>
									</div>
								</div>
							</div>
						</div>
					)
				}
			</Fragment>
		);
	}
}

export default translate('deleteConfirmationPopup')(DeleteConfirmationPopup);
