import React, { Component } from 'react';
import { connect } from 'react-redux';
import EditBusinessProfilePop from './EditBusinessProfilePop';
import { updateBusinessProfileAction } from '../../store/actions/businessProfileAction';
import { languages } from '../../i18n';
import { translate } from 'react-i18next';

class EditBusinessProfilePopContainer extends Component {
	constructor (props) {
    super(props);
		const { business, businessId/*, t*/ } = this.props;
		const descripionLangs = {}
		for (let lang of languages) {
			descripionLangs[`description_${lang}`] = business[`description${lang.charAt(0).toUpperCase() + lang.slice(1)}`] || ""
		}
    this.state = {
      houseNo: business.houseNo || "",
      street: business.street || "",
      town: business.town || "",
      zipCode: business.zipCode || "",
      barName: business.barName || "",
      contactName: business.contactName || "",
			contactEmail: business.contactEmail || "",
      contactPhone: business.contactPhone || "",
			descriptions: descripionLangs,
      gps: business.gps || "",
      ico: business.ico || "",
			opening: (business.opening || "").toString().substring(0,5),
			closing: (business.closing || "").toString().substring(0,5),
			mondayOpeningHours: business.openingHours[0] || '',
			tuesdayOpeningHours: business.openingHours[1] || '',
			wednesdayOpeningHours: business.openingHours[2] || '',
			thursdayOpeningHours: business.openingHours[3] || '',
			fridayOpeningHours: business.openingHours[4] || '',
			saturdayOpeningHours: business.openingHours[5] || '',
			sundayOpeningHours: business.openingHours[6] || '',
      businessId: businessId,
			website: business.website || '',
      ifSuccess: null,
			clicked:false,
			errors:{},
			actionId:'',
    }
  }
	componentDidUpdate(prevProps){
		if (prevProps.errorMessage!==this.props.errorMessage && this.state.clicked===true) {
			this.setState({errors:this.props.errorMessage});
		}
		if (prevProps.success!==this.props.success && (this.props.success === this.state.actionId)) {
			this.setState({errors:{}, ifSuccess: this.state.actionId});
		}
	}
  editProfile = (weekOpeningHours) => {
    const actionId =  Math.random();
		this.setState({clicked:true,actionId})
    this.props.edit(this.state, weekOpeningHours, actionId);
    this.setState(prevState => ({
      ifSuccess: prevState.ifSuccess===null ? actionId : null,
    }))
  }

  render() {
    const { isVisible, handleClickToEdit, success } = this.props;
    const { ifSuccess, errors } = this.state;

    return (
        <EditBusinessProfilePop
          formData={this.state}
          onChange={v=>this.setState(v)}
					error={errors}
          ifSuccess={ifSuccess	===success}
          action={this.editProfile}
          isVisible={isVisible}
          handleClickToEdit={handleClickToEdit} />
    );
  }
}

function mapStateToProps(state) {
  return {
		errorMessage:state.error,
    success:state.success,
  }
}
function mapDispatchToProps(dispatch) {
  return {
      edit: (editInfo, weekOpeningHours, actionId) => dispatch(updateBusinessProfileAction(editInfo, weekOpeningHours, actionId))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(translate('editBusinessProfilePopContainer')(EditBusinessProfilePopContainer));
