import {GET_REPEATING_PASSWORD_ERROR} from '../actions/repeatingPasswordErrorAction';
import humps from 'humps';

export default (state = null, action) => {
  switch (action.type) {
    case GET_REPEATING_PASSWORD_ERROR:
      const repeatingPasswordError = humps.camelizeKeys(action.repeatingPasswordError)
    return repeatingPasswordError;
    default:
      return state;
  }
}
