import React from 'react';
import { translate } from 'react-i18next';

const Footer = (props) => {
	const { t } = props;
	return (
		<div className="footer">
      &copy;{` FreeDrinks s.r.o. | ${t('createdBy')}`}&nbsp;<a href="https://thinkeasy.cz">Think Easy s.r.o.</a>
		</div>
	)
}

export default translate('footer')(Footer);
