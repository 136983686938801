import React, {Component} from 'react';
import LoginBusinessFormContainer from '../../../components/loginBusinessForm/LoginBusinessFormContainer';

class LoginPage extends Component {
	render() {
		return <LoginBusinessFormContainer {...this.props} />;
	}
}

export default LoginPage;
