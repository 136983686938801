import React, {Component} from 'react';
import { translate } from 'react-i18next';
import { UserIcon, ListIcon } from '../../styles/icons/icons';

class BusinessLabel extends Component {
	render() {
		const { user, onClickBusinessProfile, onClickBusinessDrinks, isBusinessProfileActive, isBusinessDrinksActive, t } = this.props;
		const business = (user && user.business) ? user.business : {};

		let background;
		if (business.images) {
			background = (business.images.find(e => e.isTitle === true)) || "";
		}
		return (
			<div className="business-label-container">

				<div key="business-image" className="mx-auto d-block business-image">
					{ (background && background !== "") && <img src={background.image||""} width="100%" height="100%" style={{borderRadius: 10, zIndex: 10, backgroundPosition: 'center', backgroundRepeat: 'no-repeat', objectFit: 'cover', objectPosition: 'center'}} alt="" /> }
				</div>
				<div>
					<h2 className="tac business-label-header upper">{ business.barName || "Bar name" }</h2>
					<p className="tac business-label-description">{ business.description || "Bar description" }</p>
				</div>

				<div key="buttons-container" className="buttons-container">
					<div className={isBusinessProfileActive ? "business-button business-button-active" : "business-button"} title={t('businessProfile')} onClick={onClickBusinessProfile}>
						<div key="profile-button" className="flex">
							<div key="profile-icon" className="icon-container">
								{isBusinessProfileActive ? <UserIcon fillColor="#E5407C" /> : <UserIcon fillColor="#555B6B" /> }
							</div>
							<label>{t('businessProfile')}</label>
						</div>
					</div>
					<div className={isBusinessDrinksActive ? "business-button business-button-active" : "business-button"} title={t('drinksOverview')} onClick={onClickBusinessDrinks}>
						<div key="drinks-button" className="flex">
							<div key="drink-icon" className="icon-container">
								{isBusinessDrinksActive ? <ListIcon fillColor="#E5407C" /> : <ListIcon fillColor="#555B6B" /> }
							</div>
							<label>{t('drinksOverview')}</label>
						</div>
					</div>
				</div>
			</div>
		);
	}
}

export default translate('businessLabel')(BusinessLabel);
