import React, { Component } from 'react';
import { connect } from 'react-redux';
import RegistrationBusinessForm from './RegistrationBusinessForm';
import registerBusinessAction from '../../store/actions/registerBusinessAction';

class RegistrationBusinessFormContainer extends Component {
	constructor (props) {
	    super(props)
			const descripionLangs = {}
			for (let lang of this.props.languages) {
				descripionLangs[lang] = ''
			}
	    this.state = {
				email: '',
				barName: '',
				ico: '',
				town: '',
				street: '',
				houseNo: '',
				zipCode: '',
				gps: '',
				descriptions: descripionLangs,
				contactName: '',
				contactEmail: '',
				contactPhone: '',
				clicked:false,
      	errors:{},
		  	ifSuccess: null,
				actionId:'',
		 	}
  	};

		componentDidUpdate(prevProps){
	    if (prevProps.errorMessage!==this.props.errorMessage && this.state.clicked===true) {
	      this.setState({errors:this.props.errorMessage});
	    }
			if (prevProps.success!==this.props.success && (this.props.success === this.state.actionId)) {
	      this.setState({errors:{}, ifSuccess: this.state.actionId});
	    }
	  }

  	register = () => {
			const actionId =  Math.random();
			this.setState({actionId})
			this.props.registration(this.state, actionId);
			this.setState(prevState => ({
				ifSuccess: prevState.ifSuccess===null ? actionId : null,
			}))
		}


  render() {
    const { errors, ifSuccess } = this.state;
    return (
      <RegistrationBusinessForm
				formData={this.state}
				error={errors}
				lang={this.props.lang}
				ifSuccess={ifSuccess===this.props.success}
				onChange={v=>this.setState(v)}
				languages={this.props.languages}
				action={()=>{this.setState({'clicked':true});this.register()}} />
    );
  }
}

function mapStateToProps(state) {
  return {
		lang: state.lang,
		errorMessage: state.error,
    success: state.success,
  }
}
function mapDispatchToProps(dispatch) {
  return {
      registration: (registrationInfo, actionId) => dispatch(registerBusinessAction(registrationInfo, actionId))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(RegistrationBusinessFormContainer);
