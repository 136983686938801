import React, {Component, Fragment} from 'react';
import { translate } from 'react-i18next';
import Button from '../common/button/Button';
import Image from '../image/Image';
import { UploadIcon } from '../../styles/icons/icons';


const btnStyle = {fontSize: 9, height: 30, margin: "0 20px", textTransform: "uppercase", fontWeight: 300, textAlign: "center", lineHeight: 0};

class ImagesOverview extends Component {
	render() {
		const {user, handleChangeOfDrinkName, handleClickToUpload, /*deleteImage,*/ changeProfilePicture, handleClickToConfirm, t } = this.props;

		let imgs;
		const b = (user && user.business) ? user.business : {};
		const bId = b.id ? b.id : 0;
		let images
		if (b !== {}) {
			images = b.images ? b.images : [];
			if (images !== []) {
				imgs = images.map(img => {
					return <Image key={img.id} id={img.id} src={img.image} handleChange={handleChangeOfDrinkName} isProfilePicture={img.isTitle} changeProfilePicture={changeProfilePicture} handleClickToConfirm={handleClickToConfirm} />
				});
			}
		}

		const ImagesOverview = (
			<Fragment>
				<div className="images-overview-header">
					<label className="images-overview-header-text upper">
						{t('companyPics')}
					</label>
					{ images.length < 5 &&
						<Button id="uploadPhoto" title={t('uploadPhoto')} style={btnStyle} onClick={e => handleClickToUpload(e, bId)} >
							<span className="upload-photo-icon"><UploadIcon /></span>
							{t('uploadPhoto')}
						</Button>
					}
				</div>
				<div style={{display: "flex", flexWrap: "wrap"}}>
					{imgs}
				</div>
			</Fragment>
		);

		return (
			<Fragment>{ImagesOverview}</Fragment>
		);
	}
}

export default translate('imagesOverview')(ImagesOverview);
