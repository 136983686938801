import {CURRENT_USER_RECEIVED} from '../actions/userAction';
import humps from 'humps';
import moment from 'moment';

export default (state = null, action) => {
  switch (action.type) {
    case CURRENT_USER_RECEIVED:
      const newUser = humps.camelizeKeys(action.user);
      newUser.birthDate = moment(newUser.birthDate);
      return newUser;
    default:
      return state;
  }
}
