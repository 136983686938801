import React, { Component, Fragment } from 'react';
import Input from './Input';


class LabeledInput extends Component {

  render() {
  	const {name, rawValue, beforeRawValue, outer,inner, errorMsg, visibleHints, ...rest} = this.props;
    return (
      <Fragment>
        {
          (visibleHints === true) ?
            (
              <label className="label-style-flex">
                {beforeRawValue}
                <span className="hint">{name}</span>
                <span className="input-container">
                  <Input {...rest}/>
                  {inner}
                </span>
                {rawValue}
              </label>
            )
          :
            (
              <label className="label-style">
                <Input {...rest} />
                {this.props.rawValue}
              </label>
            )
        }
        {errorMsg ? <div className="errorMsg">{errorMsg}</div> : null}
      </Fragment>
    );
  }
}

export default LabeledInput;
