import { BUSINESS_LIST_RECEIVED } from '../actions/businessListAction';
import humps from 'humps';

export default (state = [], action) => {
  	switch (action.type) {
    	case BUSINESS_LIST_RECEIVED:
      		const newBusinessList = humps.camelizeKeys(action.businessList)
      		return newBusinessList;
    	default:
    		return state;
  	}
}
