import React, { Component } from 'react';
import { translate} from 'react-i18next';
import Button from '../common/button/Button';
import Form from '../common/form/Form';
import { Redirect } from 'react-router';
import { Link } from 'react-router-dom';
import Logo from '../../styles/icons/logo.svg';

const btnStyle = {color: "#FEFEFE",	fontFamily: "Poppins",	fontSize: 16,	fontWeight: "bold",	textAlign: "center", backgroundColor: "#FE2183"}

class RegistrationBusinessForm extends Component {
	constructor(props) {
		super(props)
		const schema = this.updateSchema(false)
		this.state = {
			schema: schema,
			ifChange: false,
		}
		this.announceSuccess = this.announceSuccess.bind(this);
		this.updateSchema = this.updateSchema.bind(this);
	}
	updateSchema(shouldUpdateState = true) {
		const { formData, error, t } = this.props;
		const schema = []
		for (let data in formData) {
			let name = t(data)
			switch(data) {
				case "email":
					schema.push({name:name, path:data, type:'email', errorMsg:error.email?error.email:null, isInvalid:error.email?'errorClass':''})
					break
				case "barName":
					schema.push({name:name, path:data, type:'barName', errorMsg:(error.business||{}).barName?(error.business||{}).barName:null, isInvalid:(error.business||{}).barName?'errorClass':''})
					break
				case "ico":
					schema.push({name:name, path:data, type:'ico', errorMsg:(error.business||{}).ico?(error.business||{}).ico:null, isInvalid:(error.business||{}).ico?'errorClass':''})
					break
				case "town":
					schema.push({name:name, path:data, errorMsg:(error.business||{}).town?(error.business||{}).town:null, isInvalid:(error.business||{}).town?'errorClass':''})
					break
				case "street":
					schema.push({name:name, path:data, errorMsg:(error.business||{}).street?(error.business||{}).street:null, isInvalid:(error.business||{}).street?'errorClass':''})
					break
				case "houseNo":
					schema.push({name:name, path:data, type:'houseNo', errorMsg:(error.business||{}).houseNo?(error.business||{}).houseNo:null, isInvalid:(error.business||{}).houseNo?'errorClass':''})
					break
				case "zipCode":
					schema.push({name:name, path:data, type:'zipCode', errorMsg:(error.business||{}).zipCode?(error.business||{}).zipCode:null, isInvalid:(error.business||{}).zipCode?'errorClass':''})
					break;
				case "gps":
					schema.push({name:name, path:data, errorMsg:(error.business||{}).gps?(error.business||{}).gps:null, isInvalid:(error.business||{}).gps?'errorClass':''})
					break
				case "contactName":
					schema.push({name:name, path:data, errorMsg:(error.business||{}).contactName?(error.business||{}).contactName:null, isInvalid:(error.business||{}).contactName?'errorClass':''})
					break
				case "contactEmail":
					schema.push({name:name, path:data, errorMsg:(error.business||{}).contactEmail?(error.business||{}).contactEmail:null, isInvalid:(error.business||{}).contactEmail?'errorClass':''})
					break
				case "contactPhone":
					schema.push({name:name, path:data, type:'tel', errorMsg:(error.business||{}).contactPhone?(error.business||{}).contactPhone:null, isInvalid:(error.business||{}).contactPhone?'errorClass':''})
					break
				case "descriptions":
					break
				default:
					break
			}
		}
		for (let description in formData.descriptions) {
			let path = `descriptions.${description}`
			if (description === 'description_cs') {
				schema.push({name:t(description), path:path, errorMsg:(error.business||{}).description?(error.business||{}).description:null, isInvalid:(error.business||{}).description?'errorClass':'' })
			} else {
				schema.push({name:t(description), path:path, })
			}
		}
		if (shouldUpdateState)
			this.setState({schema:schema, ifChange: false})
		else
			return schema
	}
	announceSuccess() {
		const { ifSuccess, t } = this.props;
		if (ifSuccess === true) {
			alert(t('successfulRegistration'))
		}
	}
	componentDidUpdate(prevProps){
		if (prevProps.error!==this.props.error) {
			this.setState({ifChange: true});
		}
	}
	render() {
		const { formData, onChange, error, action, ifSuccess, lang, t } = this.props;
		const { schema, ifChange } = this.state;
		if (ifChange) {
			this.updateSchema()
		}
		const errLabel = (error.nonFieldErrors||[])[0] ? <label style={{color:"red"}}>{error.nonFieldErrors[0]}</label> : '';
		return (
			<div className="bg-bar-registration">
				{this.announceSuccess()}
				<div className="bg-bar-blur bg-bar-blur-registration">
					<div className="registration-form-container tac">
						{ifSuccess===true?<Redirect to='login' />:null}
						<Link to="/"><div className="appName"><img src={Logo} width="170px" height="58px" alt="logo" /></div></Link>
						<div className="registration-form">
							<h3 className="form-header">{t('registerYourBusiness')}</h3>
							<Form
		            formData={formData}
		            onChange={onChange}
                schema={schema}/>
	          	<Button title={t('register')} onClick={()=>{action();this.updateSchema()}} style={btnStyle}>{t('register')}</Button>
							{errLabel}
							<div className="flex options-links-container">
								<a title={t('businessConditions')} className="options-link tal" href={lang === "cs" ? "https://freelyapp.cz/zasady-ochrany-ou/" : "https://freelyapp.cz/en/data-privacy-policy/"} target="_blank" rel="noopener noreferrer">{t('businessConditions')}</a>
								<Link title={t('alreadyAccount')} className="options-link tar" to="/login">{t('alreadyAccount')}</Link>
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	}
}

export default translate('registrationBusinessForm')(RegistrationBusinessForm);
