import React from 'react';
import ReactDOM from 'react-dom';
import './styles/css/index.css';
import App from './App';
//import registerServiceWorker from './registerServiceWorker';
import { unregister as unregisterServiceWorker } from './registerServiceWorker'
import { Provider } from 'react-redux';
//import store from './store/store.js';
import i18n from './i18n.js'; // initialized i18next instance
import { I18nextProvider } from 'react-i18next';
import { store, persistor } from './store/store'
import { PersistGate } from 'redux-persist/integration/react'


ReactDOM.render(
  	<Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <I18nextProvider i18n={ i18n }>
        <div className="h100">
          <link
          rel="stylesheet"
          href="https://maxcdn.bootstrapcdn.com/bootstrap/4.0.0/css/bootstrap.min.css"
          integrity="sha384-Gn5384xqQ1aoWXA+058RXPxPg6fy4IWvTNh0E263XmFcJlSAwiGgFAW/dAiS6JXm"
          crossOrigin="anonymous" />
          <App />
        </div>
        </I18nextProvider>
      </PersistGate>
  	</Provider>
 ,document.getElementById('root'));
unregisterServiceWorker();
//registerServiceWorker();
