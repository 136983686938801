import React, { Component } from 'react';
import { connect } from 'react-redux';
import ChangePasswordPop from './ChangePasswordPop';
import { userPasswordChangeAction } from '../../store/actions/userAction';

class ChangePasswordPopContainer extends Component {
	constructor(props){
	    super(props);
	    this.state={ oldPassword:'', newPassword:'', newPasswordAgain: '', ifSuccess: null, errors:{}, clicked:false, actionId:'', }
  	}
		changePassword = () => {
	    const actionId =  Math.random();
			this.setState({clicked:true,actionId})
	    const { oldPassword, newPassword } = this.state;
	    this.props.passwordChange(oldPassword, newPassword, actionId);
	    this.setState(prevState => ({
	      ifSuccess: prevState.ifSuccess===null ? actionId : null,
				//ifSuccess: actionId
	    }))
  	}
		componentDidUpdate(prevProps){
	    if (prevProps.errorMessage!==this.props.errorMessage && this.state.clicked===true) {
	      this.setState({errors:this.props.errorMessage, ifSuccess: null});
	    }
	    if (prevProps.success!==this.props.success && (this.props.success === this.state.actionId)) {
	      this.setState({errors:{}});
	    }
	  }

  	render() {
    	const { /*edit, create, */isVisible, handleClickToChange, success } = this.props;
    	const { oldPassword, newPassword, newPasswordAgain, ifSuccess, errors /*, actionId*/ } = this.state;
		return (
			<ChangePasswordPop
				formData={this.state}
				onChange={v=>this.setState(v)}
				action={this.changePassword}
				error={errors||{}}
				ifSuccess={ifSuccess===success}
				disabled={((newPassword==='')||(newPasswordAgain==='')||(oldPassword===''))||((newPassword!==newPasswordAgain)||(oldPassword===newPassword))}
				isVisible={isVisible}
				handleClickToChange={handleClickToChange} />
		);
  	}
}
function mapStateToProps(state) {
  return {
    success:state.success,
		errorMessage:state.error,
  }
}

function mapDispatchToProps(dispatch) {
  return {
    passwordChange: (oldPassword, newPassword, actionId) => dispatch(userPasswordChangeAction(oldPassword, newPassword, actionId))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ChangePasswordPopContainer);
