import React, {Fragment, Component} from 'react';
import CustomerContainer from './../../components/customer/CustomerContainer'
import TopBarContainer from './../../components/topBar/TopBarContainer';
import Footer from '../../components/footer/Footer'

class CustomerIndex extends Component {
	render() {
		return (
			<Fragment>
				<div className="page-content-container">
					<TopBarContainer topbarStyle="customer" />
					<CustomerContainer />
				</div>
				<Footer />
			</Fragment>
		)
	}
}

export default CustomerIndex;
