import React, {Component, Fragment} from 'react';
import { translate } from 'react-i18next'
import Button from '../common/button/Button'
import moment from 'moment'
import { VerifiedIcon, UnverifiedIcon } from '../../styles/icons/icons'

class Customer extends Component {
	constructor(props) {
			super(props)
			let isMembershipActive = this.isMembershipActive()
			this.state = {
				isCancelMembershipPopVisible: false,
				isMembershipActive: isMembershipActive||false,
				termsAgreement: false,
			}
	}

	isMembershipActive = () => {
		return moment().format('YYYY-MM-DD') <= this.props.user.customer.membership.validUntil
	}

	handleCancelMembershipPopActivation = () => {
		this.setState({isCancelMembershipPopVisible: !this.state.isCancelMembershipPopVisible})
	}

	handleCheckoxChange = (event) => {
    this.setState({
      termsAgreement: event.target.checked
    });
  }

	render() {
		const {cancelMembership, makePaymentAction, membership, user, lang, t} = this.props
		return (
			<div style={{width: "100%", display: 'flex', justifyContent: 'center'}}>
				<div className="customer-container">

						<div className="customer-order-overview-header">
							<label className="customer-order-overview-header-text upper">
								{t('subscription')}
							</label>
						</div>
						<div className="customer-overview-container">
							{ this.state.isMembershipActive ?
								<Fragment> <VerifiedIcon fillColor="#ffffff" /> <label className="customer-overview-text" style={{marginLeft: 10}}>{t('subscriptionInfo')}&nbsp;<span style={{fontSize: 15, fontWeight: 600}}>{moment(user.customer.membership.validUntil).format('DD.MM.YYYY')}</span></label></Fragment>
								:
								<Fragment> <UnverifiedIcon fillColor="#ffffff" /><label className="customer-overview-text" style={{marginLeft: 10}}>{t('subscriptionNotValid')}</label></Fragment>
							}
						</div>

						<div className="big-promo">
							{`${ this.state.isMembershipActive ? t('extend') : t('buy') } ${t('promo')}`}
						</div>

						<div className="small-promo">
							*{t('promo2')}
						</div>

						<div className="customer-order-overview-header">
							<label className="customer-order-overview-header-text upper">
								{t('orderSummaryInfo')}
							</label>
						</div>

						<div className="customer-overview-container" style={{marginBottom: 60}}>
							{ membership.membershipPrice > 0 && <label className="customer-overview-text">{`${t('price')}` }&nbsp;<span style={{fontSize: 15, fontWeight: 600}}>{`${membership.membershipPrice.toString()} ${t('currency')}`}</span></label> }
							<div className="payment-info">{t('paymentInfo')}</div>
							<div className="payment-info" style={{marginBottom: 20}}>{t('repeatingPayment')}</div>
							{ (this.state.isMembershipActive && !user.customer.membership.isCancelled) ?
								(<div style={{width: "100%", display: 'flex', justifyContent: 'center'}}><Button title={t('cancel')} onClick={()=>{this.handleCancelMembershipPopActivation()}} style={{margin: "2px 4px", fontWeight: "bold", fontSize: 14, height: 40, width: 140}}>{t('cancel')}</Button></div>)
								:
								(
									<Fragment>
										<div className="checkbox">
										  <input id="termsAgreement" type="checkbox" checked={this.state.termsAgreement} onChange={this.handleCheckoxChange} />
											<label className="payment-info" style={{marginTop: -8, marginLeft: 4}}>
												{t('terms1a')}
												<a className="payment-info-link" href={lang === "cs" ? "https://freelyapp.cz/obchodni-podminky/" : "https://freelyapp.cz/en/terms-and-conditions/"} target="_blank" rel="noopener noreferrer">{t('terms')}</a>
												{t('terms1b')}
											</label>
										</div>
										{this.state.termsAgreement===false && <div className="payment-info" style={{color: "#ff0000"}}>{t('youMustAgree')}</div>}
										<div style={{width: "100%", display: 'flex', justifyContent: 'center'}}><Button title={t('pay')} disabled={this.state.termsAgreement===false} onClick={()=>makePaymentAction()} style={{margin: "2px 4px", fontWeight: "bold", fontSize: 14, height: 40, width: 140}}>{t('pay')}</Button></div>
									</Fragment>
								)
							}
						</div>
				</div>

				{ this.state.isCancelMembershipPopVisible &&
					<div className="outer-delete-container" onClick={()=>this.handleCancelMembershipPopActivation()}>
						<div className="delete-form-container">
							<div className="edit-form">
								<span className="closeIcon" aria-hidden="true" onClick={()=>this.handleCancelMembershipPopActivation()}>×</span>
								<p className="tal popup-header" key="header">{t('header')}</p>
								<div className="flex upload-container">
									<Button title={t('close')} onClick={()=>this.handleCancelMembershipPopActivation()} style={{marginRight: 10, border: "1px solid #fff",  background: "transparent", fontSize: 13}}>{t('close')}</Button>
									<Button title={t('cancelMembership')} onClick={()=>{this.handleCancelMembershipPopActivation();cancelMembership();}} style={{fontSize: 13}}>{t('cancelMembership')}</Button>
								</div>
							</div>
						</div>
					</div>
				}
			</div>
		);
	}
}

export default translate('customer')(Customer);
