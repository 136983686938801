import React, {Component} from 'react';
import ForgottenPasswordFormContainer from '../../../components/forgottenPasswordForm/ForgottenPasswordFormContainer';

class ForgottenPasswordPage extends Component {
	render() {
		return <ForgottenPasswordFormContainer />;
	}
}

export default ForgottenPasswordPage;
