import React, {Component} from 'react';
import { translate } from 'react-i18next';

class BigDrinkLabel extends Component {
	render() {
		const { drink, t } = this.props;
		return (
			<div className="big-drink-label-container">
				<div className="big-drink-label-image">
					<img src={drink.image} width="100%" height="100%" alt="" style={{borderRadius: "10px 0 0 10px", backgroundPosition: 'center', backgroundRepeat: 'no-repeat', objectFit: 'cover', objectPosition: 'center'}}/>
				</div>
				<div className="big-drink-label-rectangle"></div>
				<div className="big-drink-label-info-container">
					<label key="name" className="big-drink-label-name upper">{drink.name}</label>
					<label key="description" className="big-drink-label-description">{drink.description|| ""}</label>
					<label key="price" className="big-drink-label-price">{`${t('drinkPrice')} ${drink.price} ${t('currency')}`}</label>
				</div>
			</div>
		);
	}
}

export default translate('bigDrinkLabel')(BigDrinkLabel);
