import React, { Component } from 'react';
import { translate } from 'react-i18next';
import Button from '../common/button/Button';
import Form from '../common/form/Form';
import { Link } from 'react-router-dom';
import Logo from '../../styles/icons/logo.svg';

const btnStyle = {color: "#FEFEFE",	fontFamily: "Poppins",	fontSize: 16,	fontWeight: "bold",	textAlign: "center",	backgroundColor: "#FE2183"}

class LoginBusinessForm extends Component {
	render() {
		const { action, formData, onChange, error, t } = this.props;
		const errLabel = (error.nonFieldErrors||[])[0] ? <label style={{color:"red"}}>{error.nonFieldErrors[0]}</label> : '';
		return (
			<div className="bg-bar-login">
				<div className="bg-bar-blur bg-bar-blur-login">
					<div className="login-form-container tac">
						<Link to="/"><div className="appName"><img src={Logo} width="170px" height="58px" alt="logo" /></div></Link>
						<div className="registration-form">
							<h3 className="form-header">{t('logInYourBusiness')}</h3>
							<Form
				            formData={formData}
				            onChange={onChange}
                  	schema={[
					            {name:t('email'), path:'email', type:'email', isInvalid:error.email?'errorClass':'', errorMsg:error.email?error.email:null },
					            {name:t('password'), path:'password', type: 'password', isInvalid:error.password?'errorClass':'', errorMsg:error.password?error.password:null },
				          	]}/>
			          	<Button title={t('logIn')} onClick={action} style={btnStyle}>{t('logIn')}</Button>
							{/*<div>
		          		<label>Testing:</label>
		          		<div onClick={() => onChange({email:"bar@bar.cz",password: "bar"})}>Business</div>
							</div>*/}
							{errLabel}
							<div className="flex options-links-container">
								<Link title={t('register')}className="options-link tal" to="/register">{t('register')}</Link>
								<Link title={t('forgottenPassword')} className="options-link tar" to="/forgottenPassword">{t('forgottenPassword')}</Link>
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	}
}

export default translate('loginBusinessForm')(LoginBusinessForm);
