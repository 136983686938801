import React, { Component, Fragment } from 'react';
import { translate } from 'react-i18next';
import Button from '../common/button/Button';
import Form from '../common/form/Form';


class ChangePasswordPop extends Component {
	render() {
		const { formData, onChange, action, isVisible, handleClickToChange, disabled, ifSuccess, error, t} = this.props;
		const errLabel = error.detail? <label style={{color:"red"}}>{error.detail}</label> : '';
		return (
			<Fragment>
				{
					isVisible &&
					(
						<div className="outer-change-container" onClick={(ifSuccess === true) ? handleClickToChange({}, true) : e => handleClickToChange(e)} >
							<div className="change-form-container tac">
								<div className="edit-form">
									<span className="closeIcon" aria-hidden="true" onClick={e => handleClickToChange(e)}>×</span>
									<p className="tal popup-header" key="header">{t('changeYourPassword')}</p>
									<Form
				            formData={formData}
				            onChange={onChange}
										backgroundDark={true}
                  	schema={[
					            {name:t('oldPassword'), path:'oldPassword', type:'password', isInvalid:error.oldPassword?'errorClass':'', errorMsg:error.oldPassword?error.oldPassword:null},
					            {name:t('newPassword'), path:'newPassword', type: 'password', isInvalid:error.newPassword?'errorClass':'', errorMsg:error.newPassword?error.newPassword:null},
					            {name:t('newPasswordAgain'), path:'newPasswordAgain', type: 'password', isInvalid:error.newPasswordAgain?'errorClass':'', errorMsg:error.newPasswordAgain?error.newPasswordAgain:null },
				          	]}/>
									{errLabel}
			          	<div className="flex options-links-container" style={{justifyContent: 'flex-end'}}>
										<Button title={t('changePassword')} disabled={disabled} onClick={() => {action()}} style={disabled ? {opacity: 0.5, backgroundColor: "#FE2183", fontSize: 14} : {backgroundColor: "#FE2183", fontSize: 14}}>{t('changePassword')}</Button>
									</div>
								</div>
							</div>
						</div>
					)
				}
			</Fragment>
		);
	}
}

export default translate('changePasswordPop')(ChangePasswordPop);
